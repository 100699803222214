.slider-image
  background-size cover
  background-position center center
  position relative
  // height 100%
  // min-height 45vh
  // max-height 70vh
  height: 45vh
  min-height: 240px
  max-height: 320px
  
  // min-height: 45vh
  // max-height: 360px
  +above(medium)
    // height 100%
    min-height 300px
    max-height: 345px
  +above(large)
    min-height 325px
    max-height: 400px

.banner, .slidercontainer
  min-height 150px

.slidercontainer .banner-content, .banner .banner-content
  position absolute
  max-width 72em
  margin 0 auto
  height 100%
  right: 0
  left: 0
  width: 100%

.slidercontainer .slide_content, .banner .slide_content
  position absolute
  bottom 2rem
  padding 10px
  width 85%
  text-align right
  background-color brand
  background-color rgba(115, 220, 255, 0.8)
  transform translate(0)
  margin 0 7.5%
  +above(mobile)
    margin 0
    bottom 3rem
    right 3.5%
    width auto
    padding-left 2rem
    max-width 65%
  +above(medium)
    max-width 55%

.slide_content
  h2
    color #FFF
    margin 0
    // font-size 1.5rem
    // +above(mobile)
      // font-size 1.7rem
    // +above(medium)
    //   font-size 2rem

.banner
  img
    height auto
    width 1920px

// Cleaning up the home banner GitHub Issue #3
.home-hero
  padding: 4rem 0 0
  position: relative
  margin-bottom: 3.5rem
  background-cover()
  +above(medium)
    padding: 4.5rem 0
    margin-bottom: 2rem
  +above(large)
    padding: 5rem 0 6rem
  +above($x-large)
    padding: 6rem 0 6.5rem
    margin-bottom: 2.5rem
  
.hh-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: linear-gradient(130deg, rgba(115,220,255,0.75) 40%, transparent 100%)
  z-index: 1
  
// hh-content  
.hh-content
  position: relative
  wrapper()
  flex-layout()
  z-index: 2
  +above(medium)
    flex-layout(row)
    justify-content: space-between

.hh-text
  margin-bottom: 1rem
  padding: 0 1rem
  +above(medium)
    width: 48%
    align-self: center

.hh-text h1
  line-height 1.4em
  // color: $text-white
  color: white
  // font-size 2rem
  margin-bottom 0
  +above(large)
    font-size 2.1rem
  // +above(medium)
  //   font-size: 2.2rem
  // +above(large)
  //   font-size: 2.45rem

// hh-video
.hh-video
  transform: translateY(20px)
  +above(medium)
    width: 48%

.hh-video iframe
  width: 100%
  height: 260px
  +above($small)
    height 360px
  +above(medium)
    height 300px
  +above(large)
    height 380px
    
// Interior Page Hero Title
.page-hero-title
  padding-top: 4rem
  +above(medium)
    padding-top: 6rem   