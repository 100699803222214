.tp-testimonials
  main 
    +above(medium)
      width 65%
  aside
    +above(medium)
      width 25%

.testimonial 
  padding-bottom 2rem
  border-bottom 1px solid #EEE
  margin-bottom 2rem
  
.testimonial-title
  color brand
  margin-bottom 2rem

.quoter-meta
  margin-top 2rem
  padding 0 5%
  +above(medium)
    text-align center
    padding-right 3%
    padding-left 3%

.quoter-title
  color dark-blue
  margin-bottom 0
  +above(medium)
    padding-top 1rem
    border-top 1px solid #eee

.quoter-role
  color brand
  font-weight 400

.clients-title
  text-align center
  width 85%
  max-width 520px
  margin 0 auto
  color text
  margin 4rem auto

.client-logos
  display flex
  flex-flow row wrap

.client-logo
  margin-bottom 2rem
  width 100%
  border-right 2px solid #EEE
  border-bottom 2px solid #EEE
  width 50%
  height 200px
  padding 2rem
  margin-bottom 0
  
  display flex
  align-items center
  
  &:last-of-type
    margin-bottom 0
  &:nth-of-type(2n)
    border-right none
  &:nth-last-child(-n+2)
    border-bottom none
  
  +above(700px)
    width 33.3333%
    padding 3rem
    height 300px
    &:nth-of-type(2n)
      border-right 2px solid #EEE
    &:nth-of-type(3n)
      border-right none
    &:nth-last-child(-n+2)
      border-bottom 2px solid #EEE
    &:nth-last-child(-n+3)
      border-bottom none
  
  img 
    max-height 180px
    display block
    margin-left auto
    margin-right auto
    img 
      max-height 180px
      display block
      margin-left auto
      margin-right auto
      +above(mobile)
        max-height 260px
