section.contact-side
  margin-bottom 3rem
  &:last-of-type
    margin-bottom 0

.contact-side-title
  color brand
  // font-size 1.2rem
  margin-bottom 1rem

span.error 
  display inline-block
  margin-top 1rem
  color red 
  font-size 1.1rem
  padding 0.6rem 1rem
  border 1px solid red

.error-text
  font-size 0.9rem
  display block
  margin-top 0.4rem
  color text
  font-family heading-font

.form-errors
  margin-bottom 3rem
  p 
    font-size 1.1rem 
    font-family heading-font
    color text 
    margin-bottom 0.2rem 
    &:last-of-type
      margin-bottom 0
    &:before
      content '*'
      margin-right 0.4rem
      
form.mailchimp-form
  display: none
  overflow: hidden
  height: 0
  width: 0

.form-captcha
  padding-top: 1rem

.btn-recaptcha
  btn-submit()
  padding: 0.8rem
  border-radius 0
  border: 1px solid #4ed3ff
  margin-top 1rem
  font-weight 700
  
// custom checkboxes
.cf-checkbox
  position relative
  display: flex
  width: 100%
  max-width: 50rem
  margin 0 auto .85rem

// the checkbox input
#cf-email-checkbox
  appearance none
  position absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  padding: 0
  opacity: 0
  cursor pointer
  border: 0
  margin: 0

  &:hover
    ~ label::before
      border-color: darken(grey,40%)
  
  &:checked
    ~ label::before
      background-color rgba(brand,1)
      border-color: brand
    ~ label::after
      opacity: 1
    
    &:hover
      ~ label::before
        border-color: darken(brand, 12%)
        background-color darken(brand, 12%)

// label styles
.cf-email-label, .cf-email-label span
  text-align left
  font-family main-font
  font-size .875rem
  background-color transparent
  cursor pointer
  line-height 1.5em
  margin 0

.cf-email-label
  padding: 5px 5px 5px 28px
  
  &::before
    content: ' '
    display block
    width: 15px
    height: 15px
    border: 1px solid darken(grey,25%)
    position absolute
    left: 0
    top: 8px
    border-radius: 3px
  
  &::after
    content: '\f00c'
    font-family "FontAwesome"
    display flex
    height: 13px
    width: 13px
    pointer-events none
    position absolute
    top: 9px
    left: 2px;
    display flex
    line-height 0
    font-size 14px
    opacity 0
    color: white
    justify-content center
    align-items center

    .cf-checkbox.icon-push &
      top 10px

  
// column form
.column-left
  +above(medium)
    width: 60%

// recaptcha
.scale-recaptcha
  +above(medium)
    height: 61px
  +above(large)
    height: 77px

  .g-recaptcha
    +above(medium)
      position relative
      transform: scale(.77)
      transform-origin: 0 0
    
    +above(large)
      transform: scale(.98)
