// Arlo overspills
.arlo-introduction
  display: flex
  flex-wrap: wrap
  margin-bottom 3rem
  +above(medium)
    justify-content space-between

  aside
    margin-top 2rem
    margin-bottom 0
    flex-basis 100%
    +above(medium)
      width: auto
      padding-left 0
      border: 0
      flex-basis: 40%
      margin-top 2.5rem

    blockquote
      margin: 0

.arlo-introduction
  &:not(.centered)
    .ai-column
      +above(medium)
        width: 52%
  
  &.centered
    .ai-column
      +above(medium)
        width: 68%
        margin: 0 auto
        text-align center

// Content
.arlo-content-fields
  .arlo-content-field-text
    +above(medium)
      width: 60%

.arlo-content-fields.full-width
  .arlo-content-field-text
    +above(medium)
      width: 100%

.arlo-h3
  @extend h3

// Card
.arlo-card-detail
  border-top: 1px solid #f3f3f3
  padding-top: .7rem
  margin-top: .7rem

.arlo-card-label
  display: block
  text-transform: uppercase
  font-size: .7rem
  letter-spacing: .5px