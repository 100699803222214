.wrap
  width 90%
  max-width 72rem
  margin-left auto
  margin-right auto

.content
  padding-top 4rem
  +above(medium)
    padding-top 6rem
  &.has-banner
    padding 2rem 0 5rem
    +above(medium)
      padding-top 2.5rem
  
  body.testimonials &
    padding: 2rem 0 5rem
    +above(medium)
      padding-top: 2.5rem
  
  body.arloCourses &
    padding-top 0

.row
  display flex
  flex-direction column
  &.reverse
    +above(medium)
      flex-direction row-reverse
  &.mobile-reverse
    +below(medium)
      flex-direction column-reverse
  +above(medium)
    flex-direction row
    flex-wrap wrap
    justify-content flex-start

.half
  margin-bottom 2rem
  &:last-of-type
    margin-bottom 0
  // &:nth-of-type(even)
  //   margin-left auto
  +above(medium)
    width 48%
    margin-bottom 0
    margin-right 4%
  &:nth-of-type(2n)
    margin-right 0

main, .main
  +above(medium)
    width 60%
    &.full
      width 100%

// full column with max width    
// main.page-content
//   &.full
//     +above(medium)
//       max-width 960px
//       width: 100%

aside
  margin-top 3rem
  &.center
    text-align center
  .reverse &
    margin-left 0
    border none
    padding 0
    margin-bottom 2rem
    +above(medium)
      margin-bottom 0
  .mobile-reverse &
    margin-top 0
    margin-bottom 2rem
  +above(medium)
    width 32%
    margin-top 0
    margin-left auto
    padding-left 5%
    border-left 1px solid #EEE
    &.no-border
      margin-left auto
      padding-left 0
      border-left none

  &.download-aside-section
    +above(medium)
      padding-left 3%

.drop 
  margin-top 6rem

.pull-right
  text-align right

// page introduction block
.page-introduction
  margin: 0 auto 2.5rem
  max-width: 560px
  text-align: center
  padding: 0
  *:last-child
    margin-bottom: 0
  
  h3
    @extends h3.large-heading

// page link block
.page-link
  max-width 780px
  margin: 0 auto 3.5rem
  box-shadow 0 0 15px 5px rgba(0, 0, 0, .06)
  border-radius 5px
  background-color white
  padding: 1.2rem 1rem
  display flex
  flex-wrap: wrap
  justify-content center

  +above($tablet)
    align-items center
    width: 90%
    flex-wrap nowrap
  
  p
    font-size .95rem
    margin-bottom .8rem
  
  &:not(.card)
    p
      flex: 1 100%
      text-align center
      font-size 1rem
      +above($tablet)
        margin-right 5%
        flex: 0 1 65%
        margin-bottom 0

.page-link.card
  display flex
  flex-direction column
  justify-content flex-start
  align-items flex-start
  margin: 0
  flex: 1 100%

  &:not(:first-of-type)
    +equalBelow($tablet)
      margin-top 2rem
  
  &:nth-of-type(n + 3)
    +above($tablet)
      margin-top 2rem

  +above($tablet)
    flex: 0 48%
    padding: 1.35rem 1.5rem

  h5
    margin-bottom .25rem
    color brand
  
  a.btn-bordered
    margin-top auto

.pause-transitions *
  transition: none !important

.admin-edit-button
  position fixed
  bottom: 0
  left: 1.5rem
  padding: .5rem 1rem .8rem
  border-radius: 4px 4px 0 0
  background-color brand
  text-decoration none
  color: white
  font-weight 700
  font-family heading-font
  transform: translate3d(0,.2rem,0)
  transition: all 200ms ease-out
  z-index 2
  &:hover
    transform: translate3d(0,0,0)
    background-color darken(brand, 5%)