*
  box-sizing border-box

body
  margin 0
  padding 0
  width 100%
  font-family main-font
  antialiased()

h1, h2, h3, h4, h5, h6
  color text
  font-family heading-font
  margin-top 0
  letter-spacing 0.05px
  margin-bottom 1em
  font-kerning normal
  line-height 1.35em

  a
    text-decoration none
    &:hover
      color darken(brand, 10%)

  aside &
    color brand
  
  &.grey 
    color grey
  &.aside-heading
    color: dark-blue
    line-height 1.5em
  &.white
    color: white
  
  &.fw-400
    font-weight 400

.title
  color text
  &.remove-push
    margin-bottom: 1rem

.side-title
  margin-bottom 1.5rem


h1
  font-size 1.7rem
  &.large-heading
    +above(medium)
      font-size 1.8rem
    +above(large)
      font-size 1.9rem
h2
  font-size 1.6rem
h3
  font-size 1.4rem
  &.large-heading
    +above(medium)
      font-size 1.45rem
h4
  font-size 1.25rem
h5
  font-size 1.1rem
h6
  font-size .9rem

.page-title
  color brand
  padding-bottom 1rem
  border-bottom 1px solid #EEE
  margin-bottom 2rem
  // font-size 1.8rem
  line-height 1.3em
  background-color brand
  border-bottom 4px solid darken(brand, 20%)
  color white
  padding 0.5rem 1rem
  display inline-block
  width auto
  max-width 85%
  +above(medium)
    // font-size 2rem
    margin-bottom 3rem
    // padding-bottom 0.2rem
  a
    color white

.lower-title
  @extends .page-title
  margin-top 4rem
  margin-bottom 3rem
  // font-size 1.6rem
  +above(medium)
    margin-bottom 4rem
    margin-top 6rem
    
.page-title.bannerless-hero
  margin-bottom: 1rem   

.page-summary
  margin-bottom 1.5rem
  +above(medium)
    margin-bottom 2rem
  p
    color brand
    font-size 1.3rem
    line-height 1.4em
    font-family heading-font
    +above(medium)
      font-size 1.5rem


.page-content
  h2, h3, h4, h5, p
    &:first-child
      margin-top 0
  p a
    color black
    margin-left 0.1rem

ul
  list-style-type none
  margin 0
  padding 0
  .page-content &
    list-style-type square
    margin 1.5rem 0 1.5rem 1.5rem
    li
      margin-bottom 0.8rem
  &.inline
    li
      display inline
      margin-right 0.6rem
      a
        display inline-block
      &:last-of-type
        margin-right 0

p, li
  font-size 1.1rem
  line-height 1.6em
  color text // #988E8B
  font-family main-font
  font-kerning normal
  font-weight 400

p
  // font-size 1.2rem
  // line-height 1.6em
  margin 0 0 1rem 0
  aside &
    font-size 1rem
  &.li 
    font-size 1.1rem
  &.center 
    text-align center
  &.small
    font-size 1.05rem

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6
  margin-top 40px

a
  color brand

// blockqoute
blockquote
  font-size 24px
  font-family heading-font
  font-style italic
  margin 60px auto
  color #777
  padding 0 0 0 2%
  border-left 4px solid #5cc2e8
  &.right
    text-align right
    padding 0 2% 0 0
    border-left none
    border-right 4px solid #5cc2e8

blockquote.callout
  margin: 0
  padding: 0
  border: 0
  p
    border-left: 4px solid $brand-orange
    border-bottom: 4px solid $brand-orange
    padding: 8px 0 22px 22px
    margin-bottom 0
    font-family heading-font
    position relative
    font-style normal
    font-weight 300
    position relative
    font-size 1.15rem
    +above($tablet)
      padding: 8px 0 30px 40px
    +above(medium)
      padding: 8px 0 22px 22px
    
    &.small
      font-size 1rem

  p.quotemarks
    &::before, &::after
      display inline
      position relative
    &::before
      content: "‘"
    &::after
      content: "’"
  
  figcaption
    position relative
    padding-top: 2rem
    text-align: right
    &::before
      content: ' '
      display: block
      position: absolute
      top: 0px
      right: 0
      width: 0
      height: 0
      border-style: solid
      border-width: 0 22px 18px 0
      border-color: transparent $brand-orange transparent transparent
    
    h5
      color: text
      font-style normal
      margin-bottom .25rem
      font-size 1rem

    span
      margin-bottom: 0
      font-size: .9rem
      font-family: 'Roboto Slab'
      display: block
      font-style normal
      line-height: 1.4em

hr
  margin 1.25rem 0 1.55rem
  
  &.push-bottom
    margin-bottom: 2.75rem



img
  max-width 100%
  height auto

.avatar
  img
    border-radius 50%

i
  &.before
    padding-right 5px
  &.after
    padding-left 5px

.icon-reorder
  position relative
  top 6.5px
  &:before
    content "\f0c9"
    font-size 1.5rem

.drop, a.drop
  display inline-block
  margin-top 2rem

hr
  border none
  border-bottom 1px solid #EEE

a img:hover
  opacity 0.96

p.browser-upgrade
  position fixed
  bottom 0
  left 0
  z-index 100
  width 100%
  background white
  border-top 1px solid #EEE
  text-align center
  padding 1rem 2rem
  margin 0

iframe
  max-width 100%
  // height auto

.download-link
  color #777
  // font-style italic
  font-size 1.1rem
  font-family heading-font
  text-decoration none
  &:hover
    color brand
  i
    margin-right 0.4rem

.side-links
  li
    text-transform uppercase
    font-size 0.8rem
    margin-bottom 1.5rem
    color brand
    a 
      color text
      font-size 1.2rem
      display block
      text-transform none
      text-decoration none
      &:hover 
        text-decoration underline

// index page
.index-list
  margin-bottom 3rem

.index-item
  &:not(:last-of-type)
    margin-bottom 2rem
    padding-bottom 2rem
    border-bottom: 1px solid grey
  
  p
    max-width 800px
    width 100%