* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Open Sans', "Lato", "Helvetica Neue", Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
.arlo-h3 {
  color: #766c6a;
  font-family: 'Roboto Slab', Georgia, serif;
  margin-top: 0;
  letter-spacing: 0.05px;
  margin-bottom: 1em;
  font-kerning: normal;
  line-height: 1.35em;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.arlo-h3 a {
  text-decoration: none;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.arlo-h3 a:hover {
  color: #40b7e4;
}
aside h1,
aside h2,
aside h3,
aside h4,
aside h5,
aside h6,
aside .arlo-h3 {
  color: #5cc2e8;
}
h1.grey,
h2.grey,
h3.grey,
h4.grey,
h5.grey,
h6.grey,
.arlo-h3.grey {
  color: #cecece;
}
h1.aside-heading,
h2.aside-heading,
h3.aside-heading,
h4.aside-heading,
h5.aside-heading,
h6.aside-heading,
.arlo-h3.aside-heading {
  color: #2e2e4d;
  line-height: 1.5em;
}
h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white,
.arlo-h3.white {
  color: #fff;
}
h1.fw-400,
h2.fw-400,
h3.fw-400,
h4.fw-400,
h5.fw-400,
h6.fw-400,
.arlo-h3.fw-400 {
  font-weight: 400;
}
.title {
  color: #766c6a;
}
.title.remove-push {
  margin-bottom: 1rem;
}
.side-title {
  margin-bottom: 1.5rem;
}
h1 {
  font-size: 1.7rem;
}
@media only screen and (min-width: 900px) {
  h1.large-heading {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1200px) {
  h1.large-heading {
    font-size: 1.9rem;
  }
}
h2 {
  font-size: 1.6rem;
}
h3,
.arlo-h3 {
  font-size: 1.4rem;
}
@media only screen and (min-width: 900px) {
  h3.large-heading,
  .arlo-h3.large-heading,
  .page-introduction h3 {
    font-size: 1.45rem;
  }
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.1rem;
}
h6 {
  font-size: 0.9rem;
}
.page-title,
.lower-title {
  color: #5cc2e8;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
  line-height: 1.3em;
  background-color: #5cc2e8;
  border-bottom: 4px solid #23ade0;
  color: #fff;
  padding: 0.5rem 1rem;
  display: inline-block;
  width: auto;
  max-width: 85%;
}
@media only screen and (min-width: 900px) {
  .page-title,
  .lower-title {
    margin-bottom: 3rem;
  }
}
.page-title a,
.lower-title a {
  color: #fff;
}
.lower-title {
  margin-top: 4rem;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 900px) {
  .lower-title {
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
}
.page-title.bannerless-hero {
  margin-bottom: 1rem;
}
.page-summary {
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 900px) {
  .page-summary {
    margin-bottom: 2rem;
  }
}
.page-summary p {
  color: #5cc2e8;
  font-size: 1.3rem;
  line-height: 1.4em;
  font-family: 'Roboto Slab', Georgia, serif;
}
@media only screen and (min-width: 900px) {
  .page-summary p {
    font-size: 1.5rem;
  }
}
.page-content h2:first-child,
.page-content h3:first-child,
.page-content h4:first-child,
.page-content h5:first-child,
.page-content p:first-child {
  margin-top: 0;
}
.page-content p a {
  color: #000;
  margin-left: 0.1rem;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.page-content ul {
  list-style-type: square;
  margin: 1.5rem 0 1.5rem 1.5rem;
}
.page-content ul li {
  margin-bottom: 0.8rem;
}
ul.inline li {
  display: inline;
  margin-right: 0.6rem;
}
ul.inline li a {
  display: inline-block;
}
ul.inline li:last-of-type {
  margin-right: 0;
}
p,
li {
  font-size: 1.1rem;
  line-height: 1.6em;
  color: #766c6a;
  font-family: 'Open Sans', "Lato", "Helvetica Neue", Arial;
  font-kerning: normal;
  font-weight: 400;
}
p {
  margin: 0 0 1rem 0;
}
aside p {
  font-size: 1rem;
}
p.li {
  font-size: 1.1rem;
}
p.center {
  text-align: center;
}
p.small {
  font-size: 1.05rem;
}
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 40px;
}
a {
  color: #5cc2e8;
}
blockquote {
  font-size: 24px;
  font-family: 'Roboto Slab', Georgia, serif;
  font-style: italic;
  margin: 60px auto;
  color: #777;
  padding: 0 0 0 2%;
  border-left: 4px solid #5cc2e8;
}
blockquote.right {
  text-align: right;
  padding: 0 2% 0 0;
  border-left: none;
  border-right: 4px solid #5cc2e8;
}
blockquote.callout {
  margin: 0;
  padding: 0;
  border: 0;
}
blockquote.callout p {
  border-left: 4px solid #fccd17;
  border-bottom: 4px solid #fccd17;
  padding: 8px 0 22px 22px;
  margin-bottom: 0;
  font-family: 'Roboto Slab', Georgia, serif;
  position: relative;
  font-style: normal;
  font-weight: 300;
  position: relative;
  font-size: 1.15rem;
}
@media only screen and (min-width: 640px) {
  blockquote.callout p {
    padding: 8px 0 30px 40px;
  }
}
@media only screen and (min-width: 900px) {
  blockquote.callout p {
    padding: 8px 0 22px 22px;
  }
}
blockquote.callout p.small {
  font-size: 1rem;
}
blockquote.callout p.quotemarks::before,
blockquote.callout p.quotemarks::after {
  display: inline;
  position: relative;
}
blockquote.callout p.quotemarks::before {
  content: "‘";
}
blockquote.callout p.quotemarks::after {
  content: "’";
}
blockquote.callout figcaption {
  position: relative;
  padding-top: 2rem;
  text-align: right;
}
blockquote.callout figcaption::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 22px 18px 0;
  border-color: transparent #fccd17 transparent transparent;
}
blockquote.callout figcaption h5 {
  color: #766c6a;
  font-style: normal;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}
blockquote.callout figcaption span {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-family: 'Roboto Slab';
  display: block;
  font-style: normal;
  line-height: 1.4em;
}
hr {
  margin: 1.25rem 0 1.55rem;
}
hr.push-bottom {
  margin-bottom: 2.75rem;
}
img {
  max-width: 100%;
  height: auto;
}
.avatar img {
  border-radius: 50%;
}
i.before {
  padding-right: 5px;
}
i.after {
  padding-left: 5px;
}
.icon-reorder {
  position: relative;
  top: 6.5px;
}
.icon-reorder:before {
  content: "\f0c9";
  font-size: 1.5rem;
}
.drop,
a.drop {
  display: inline-block;
  margin-top: 2rem;
}
hr {
  border: none;
  border-bottom: 1px solid #eee;
}
a img:hover {
  opacity: 0.96;
}
p.browser-upgrade {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
  text-align: center;
  padding: 1rem 2rem;
  margin: 0;
}
iframe {
  max-width: 100%;
}
.download-link {
  color: #777;
  font-size: 1.1rem;
  font-family: 'Roboto Slab', Georgia, serif;
  text-decoration: none;
}
.download-link:hover {
  color: #5cc2e8;
}
.download-link i {
  margin-right: 0.4rem;
}
.side-links li {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
  color: #5cc2e8;
}
.side-links li a {
  color: #766c6a;
  font-size: 1.2rem;
  display: block;
  text-transform: none;
  text-decoration: none;
}
.side-links li a:hover {
  text-decoration: underline;
}
.index-list {
  margin-bottom: 3rem;
}
.index-item:not(:last-of-type) {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #cecece;
}
.index-item p {
  max-width: 800px;
  width: 100%;
}
a.more {
  text-decoration: none;
  display: block;
  margin-top: 1.6rem;
  font-size: 1.05rem;
  font-weight: 700;
}
a.more:after {
  content: '→';
  margin-left: 0.4rem;
}
a.more:hover {
  color: #40b7e4;
}
a.more.inline-block {
  display: inline-block;
}
a.more.small {
  margin-top: 0.5rem;
}
a.more.mt-0 {
  margin-top: 0;
}
button,
.btn-solid {
  font-size: 1rem;
  font-family: 'Roboto Slab', Georgia, serif;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 0;
}
button.btn-submit,
.btn-solid.btn-submit {
  font-weight: 700;
  padding: 0.8rem;
  display: block;
  width: 100%;
}
button.btn-submit:not(.with-hover),
.btn-solid.btn-submit:not(.with-hover) {
  background-color: #73dcff;
  color: #fff;
  transition: background-color 200ms ease-out;
}
button.btn-submit:not(.with-hover):hover,
.btn-solid.btn-submit:not(.with-hover):hover {
  background-color: #61d7ff;
}
button.btn-submit.with-hover,
.btn-solid.btn-submit.with-hover {
  position: relative;
  overflow: hidden;
}
button.btn-submit.with-hover span,
.btn-solid.btn-submit.with-hover span {
  position: relative;
  color: #fff;
  z-index: 1;
}
button.btn-submit.with-hover::after,
.btn-solid.btn-submit.with-hover::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.035);
  z-index: 0;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
}
button.btn-submit.with-hover:hover::after,
.btn-solid.btn-submit.with-hover:hover::after {
  opacity: 1;
}
button.btn-submit.brand-orange,
.btn-solid.btn-submit.brand-orange {
  background-color: #fccd17;
}
button.btn-submit.brand-orange:hover,
.btn-solid.btn-submit.brand-orange:hover {
  background-color: #fac703;
}
button.btn-submit.brand-green,
.btn-solid.btn-submit.brand-green {
  background-color: #92d150;
}
button.btn-submit.brand-green:hover,
.btn-solid.btn-submit.brand-green:hover {
  background-color: #87cc3e;
}
button.btn-bordered,
.btn-solid.btn-bordered {
  display: inline-block;
  border-bottom: 4px solid #23ade0;
  border-radius: 5px;
  background-color: #5cc2e8;
  transition: all 200ms ease-out;
  color: #fff;
  padding: 0.8rem 1rem 0.6rem;
}
button.btn-bordered:hover,
.btn-solid.btn-bordered:hover {
  border-color: #1ea3d5;
  background-color: #4ebde6;
}
button.btn-bordered.btn-large,
.btn-solid.btn-bordered.btn-large {
  font-size: 1.15rem;
}
button.btn-bordered.btn-small,
.btn-solid.btn-bordered.btn-small {
  font-size: 0.95rem;
  padding: 0.6rem 1rem;
}
p.btn-contain a {
  margin-top: 0.5rem;
  font-size: 0.95rem;
  padding: 5px 18px;
}
#nav_trigger.button {
  padding: 23px 8.5px;
}
.st-button {
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 1rem;
  padding: 14px 18px;
  background: #5cc2e8;
  border: 0;
  color: #fff;
  font-weight: 700;
  border-radius: 5px 5px 0 0;
  text-align: left;
  transition: background 0.1s ease-out;
  margin: 0;
}
.st-button:hover,
.st-button.active {
  background: #2e2e4d;
  border-color: transparent;
}
.st-button:not(:first-of-type) {
  margin-left: 2px;
}
.st-button:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 740px) {
  .st-button:not(:last-of-type) {
    margin-bottom: 0;
  }
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 3rem 0 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  font-size: 0.9rem;
  line-height: 1.42857143;
  color: #5cc2e8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #5cc2e8;
  border-color: #5cc2e8;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.wrap {
  width: 90%;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}
.content {
  padding-top: 4rem;
}
@media only screen and (min-width: 900px) {
  .content {
    padding-top: 6rem;
  }
}
.content.has-banner {
  padding: 2rem 0 5rem;
}
@media only screen and (min-width: 900px) {
  .content.has-banner {
    padding-top: 2.5rem;
  }
}
body.testimonials .content {
  padding: 2rem 0 5rem;
}
@media only screen and (min-width: 900px) {
  body.testimonials .content {
    padding-top: 2.5rem;
  }
}
body.arloCourses .content {
  padding-top: 0;
}
.row {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 900px) {
  .row.reverse {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 900px) {
  .row.mobile-reverse {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 900px) {
  .row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.half {
  margin-bottom: 2rem;
}
.half:last-of-type {
  margin-bottom: 0;
}
@media only screen and (min-width: 900px) {
  .half {
    width: 48%;
    margin-bottom: 0;
    margin-right: 4%;
  }
}
.half:nth-of-type(2n) {
  margin-right: 0;
}
@media only screen and (min-width: 900px) {
  main,
  .main {
    width: 60%;
  }
  main.full,
  .main.full {
    width: 100%;
  }
}
aside {
  margin-top: 3rem;
}
aside.center {
  text-align: center;
}
.reverse aside {
  margin-left: 0;
  border: none;
  padding: 0;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  .reverse aside {
    margin-bottom: 0;
  }
}
.mobile-reverse aside {
  margin-top: 0;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  aside {
    width: 32%;
    margin-top: 0;
    margin-left: auto;
    padding-left: 5%;
    border-left: 1px solid #eee;
  }
  aside.no-border {
    margin-left: auto;
    padding-left: 0;
    border-left: none;
  }
}
@media only screen and (min-width: 900px) {
  aside.download-aside-section {
    padding-left: 3%;
  }
}
.drop {
  margin-top: 6rem;
}
.pull-right {
  text-align: right;
}
.page-introduction {
  margin: 0 auto 2.5rem;
  max-width: 560px;
  text-align: center;
  padding: 0;
}
.page-introduction *:last-child {
  margin-bottom: 0;
}
.page-link {
  max-width: 780px;
  margin: 0 auto 3.5rem;
  box-shadow: 0 0 15px 5px rgba(0,0,0,0.06);
  border-radius: 5px;
  background-color: #fff;
  padding: 1.2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (min-width: 640px) {
  .page-link {
    align-items: center;
    width: 90%;
    flex-wrap: nowrap;
  }
}
.page-link p {
  font-size: 0.95rem;
  margin-bottom: 0.8rem;
}
.page-link:not(.card) p {
  flex: 1 100%;
  text-align: center;
  font-size: 1rem;
}
@media only screen and (min-width: 640px) {
  .page-link:not(.card) p {
    margin-right: 5%;
    flex: 0 1 65%;
    margin-bottom: 0;
  }
}
.page-link.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  flex: 1 100%;
}
@media screen and (max-width: calc(640px - 1px)) {
  .page-link.card:not(:first-of-type) {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 640px) {
  .page-link.card:nth-of-type(n + 3) {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 640px) {
  .page-link.card {
    flex: 0 48%;
    padding: 1.35rem 1.5rem;
  }
}
.page-link.card h5 {
  margin-bottom: 0.25rem;
  color: #5cc2e8;
}
.page-link.card a.btn-bordered {
  margin-top: auto;
}
.pause-transitions * {
  transition: none !important;
}
.admin-edit-button {
  position: fixed;
  bottom: 0;
  left: 1.5rem;
  padding: 0.5rem 1rem 0.8rem;
  border-radius: 4px 4px 0 0;
  background-color: #5cc2e8;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-family: 'Roboto Slab', Georgia, serif;
  transform: translate3d(0, 0.2rem, 0);
  transition: all 200ms ease-out;
  z-index: 2;
}
.admin-edit-button:hover {
  transform: translate3d(0, 0, 0);
  background-color: #4ebde6;
}
header.site-header {
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 900px) {
  header.site-header {
    background-color: #2e2e4d;
  }
}
@media screen and (max-width: calc(900px - 1px)) {
  header.site-header section.mobile-wrap {
    order: 2;
    padding: 0 5%;
    max-height: 90px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2e2e4d;
    flex: 1;
  }
}
header.site-header section.inner-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media only screen and (min-width: 900px) {
  header.site-header section.inner-wrap {
    margin: 0 auto;
    width: 90%;
    max-width: 72rem;
    max-height: 90px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
.site-logo {
  width: 130px;
  height: 110px;
  padding: 14px 10px 10px 10px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-self: flex-start;
  align-items: center;
}
.site-logo img {
  max-height: 70px;
  height: auto;
  width: auto;
}
#logo {
  position: relative;
  background-color: #fff;
  text-align: center;
  top: 0.5rem;
}
#logo img {
  width: 75%;
  padding: 1.2rem 0.1rem 0.6rem;
}
@media only screen and (min-width: 900px) {
  #logo img {
    padding: 2rem 0.3rem 0.8rem;
    width: 90%;
  }
}
.contact-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}
@media only screen and (min-width: 900px) {
  .contact-header {
    display: none;
  }
}
.nav-trigger {
  padding: 1.6rem 0.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
@media only screen and (min-width: 900px) {
  .nav-trigger {
    display: none;
  }
}
.nav-trigger span {
  margin-right: 0.6rem;
  color: #fff;
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
}
.nav-trigger i {
  color: #fff;
  position: relative;
  top: 1px;
}
button.nav-item-trigger,
button.mobile-menu-trigger {
  appearance: none;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  border-radius: 0;
  background: transparent;
  font-size: 1rem;
}
button.nav-item-trigger:active,
button.mobile-menu-trigger:active {
  top: 0px;
}
button.mobile-menu-trigger {
  color: #fff;
}
@media only screen and (min-width: 900px) {
  button.mobile-menu-trigger {
    display: none;
  }
}
button.mobile-menu-trigger:hover span,
button.mobile-menu-trigger:hover i {
  color: #5cc2e8;
}
button.mobile-menu-trigger span,
button.mobile-menu-trigger i {
  transition: color 200ms ease-out;
}
button.mobile-menu-trigger span {
  margin-right: 2px;
  display: inline-block;
}
button.nav-item-trigger {
  width: 100%;
}
button.nav-item-trigger i {
  pointer-events: none;
  position: relative;
  font-size: 1.2rem;
  top: 2px;
  transform: scaleY(1);
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
  margin-left: 3px;
}
button.nav-item-trigger.js-active i {
  transform: scaleY(-1);
}
@media screen and (max-width: calc(900px - 1px)) {
  nav.header-navigation {
    order: 1;
    flex: 1 1 100%;
    overflow: hidden;
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  nav.header-navigation {
    margin-bottom: 20px;
    display: flex !important;
  }
}
@media only screen and (min-width: 900px) {
  ul.nav-parents {
    display: flex;
    align-items: baseline;
  }
}
li.nav-item {
  position: relative;
  line-height: 1em;
}
@media only screen and (min-width: 900px) {
  li.nav-item {
    display: inline-flex;
  }
  li.nav-item:not(li.nav-item:last-of-type) {
    margin-right: 20px;
  }
  li.nav-item:not(li.nav-item:last-of-type)::after {
    content: ' ';
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: -10px;
    background-color: #8686b7;
    height: 14px;
    width: 1px;
  }
}
@media screen and (max-width: calc(900px - 1px)) {
  li.nav-item:not(:first-of-type) {
    border-top: 1px solid #9ddaf1;
  }
}
li.nav-item a,
li.nav-item button.nav-item-trigger {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
  display: block;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-family: 'Roboto Slab', Georgia, serif;
}
@media screen and (max-width: calc(900px - 1px)) {
  li.nav-item a,
  li.nav-item button.nav-item-trigger {
    padding: 1.05rem;
    font-size: 1rem;
    background-color: #5cc2e8;
    text-align: center;
    transition: background-color 200ms ease-out;
  }
  li.nav-item a:hover,
  li.nav-item button.nav-item-trigger:hover,
  li.nav-item a.js-active,
  li.nav-item button.nav-item-trigger.js-active {
    background-color: #3ab5e3;
  }
}
@media only screen and (min-width: 900px) {
  li.nav-item a,
  li.nav-item button.nav-item-trigger {
    font-size: 0.9rem;
    transition: color 200ms ease-out;
  }
  li.nav-item a:hover,
  li.nav-item button.nav-item-trigger:hover {
    color: #5cc2e8;
  }
}
@media only screen and (min-width: 1200px) {
  li.nav-item a,
  li.nav-item button.nav-item-trigger {
    font-size: 0.925rem;
  }
}
@media only screen and (min-width: 900px) {
  li.nav-item:nth-last-child(-n+2) ul.nav-children {
    left: auto;
    right: -5px;
    text-align: right;
  }
  li.nav-item:nth-last-child(-n+2) ul.nav-children::before {
    right: 15px;
    left: auto;
  }
}
@media only screen and (min-width: 900px) {
  li.nav-item:first-of-type {
    display: none;
  }
}
@media only screen and (min-width: 1010px) {
  li.nav-item:first-of-type {
    display: block;
  }
}
@media screen and (max-width: calc(900px - 1px)) {
  li.nav-item.mobile-hide {
    display: none;
  }
}
ul.nav-children {
  overflow: hidden;
}
@media screen and (max-width: calc(900px - 1px)) {
  ul.nav-children {
    max-height: 0;
    transition: max-height 200ms ease-out;
  }
}
@media only screen and (min-width: 900px) {
  ul.nav-children {
    max-height: 100vh !important;
    position: absolute;
    left: -18px;
    top: 38px;
    min-width: 180px;
    width: 100%;
    box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.2);
    transform: translate3d(0, 5px, 0);
    transition: opacity 200ms ease-out, transform 250ms ease-out;
    background-color: #fff;
    overflow: visible;
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    z-index: 0;
  }
  ul.nav-children.js-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }
}
ul.nav-children li.sub-item a {
  line-height: 1.45em;
}
@media screen and (max-width: calc(900px - 1px)) {
  ul.nav-children li.sub-item a {
    background-color: #70c9eb;
    transition: background-color 200ms ease-out;
  }
  ul.nav-children li.sub-item a:hover {
    background-color: #79cdec;
  }
}
@media only screen and (min-width: 900px) {
  ul.nav-children li.sub-item a {
    background-color: #fff;
    color: #6a615f;
    padding: 0.6rem 1rem;
    transition: background-color 200ms ease-out;
  }
  ul.nav-children li.sub-item a:hover {
    background-color: #f5f5f5;
  }
}
@media only screen and (min-width: 900px) {
  ul.nav-children li.sub-item {
    overflow: hidden;
  }
  ul.nav-children li.sub-item:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }
  ul.nav-children li.sub-item:first-of-type {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  ul.nav-children li.sub-item:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
@media only screen and (min-width: 900px) {
  ul.nav-children::before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    pointer-events: none;
    top: -9px;
    left: 15px;
    z-index: -1;
  }
}
a.phone-header {
  text-decoration: none;
  font-family: 'Roboto Slab', Georgia, serif;
  display: flex;
  align-items: baseline;
  margin-left: auto;
  margin-right: 24px;
  position: relative;
  color: #fff;
}
@media only screen and (min-width: 640px) {
  a.phone-header {
    margin-right: 32px;
  }
}
@media only screen and (min-width: 900px) {
  a.phone-header {
    display: none;
  }
}
a.phone-header:hover span,
a.phone-header:hover i {
  color: #5cc2e8;
}
a.phone-header span,
a.phone-header i {
  color: inherit;
  transition: color 200ms ease-out;
}
a.phone-header span {
  font-size: 1rem;
  font-family: 'Roboto Slab', Georgia, serif;
  margin-left: 3px;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 480px) {
  a.phone-header span.prompt {
    display: none;
  }
}
a.phone-header span.number {
  display: none;
}
@media only screen and (min-width: 480px) {
  a.phone-header span.number {
    display: inline-block;
  }
}
a.phone-header i.fa {
  position: relative;
  font-size: 0.9rem;
  transform: rotate(8deg);
}
a.phone-header::after {
  content: ' ';
  display: block;
  width: 1px;
  height: 14px;
  background-color: #fff;
  position: absolute;
  right: -11px;
  top: 51%;
  transform: translate3d(0, -50%, 0);
}
@media only screen and (min-width: 640px) {
  a.phone-header::after {
    right: -16px;
  }
}
.slider-image {
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 45vh;
  min-height: 240px;
  max-height: 320px;
}
@media only screen and (min-width: 900px) {
  .slider-image {
    min-height: 300px;
    max-height: 345px;
  }
}
@media only screen and (min-width: 1200px) {
  .slider-image {
    min-height: 325px;
    max-height: 400px;
  }
}
.banner,
.slidercontainer {
  min-height: 150px;
}
.slidercontainer .banner-content,
.banner .banner-content {
  position: absolute;
  max-width: 72em;
  margin: 0 auto;
  height: 100%;
  right: 0;
  left: 0;
  width: 100%;
}
.slidercontainer .slide_content,
.banner .slide_content {
  position: absolute;
  bottom: 2rem;
  padding: 10px;
  width: 85%;
  text-align: right;
  background-color: #5cc2e8;
  background-color: rgba(115,220,255,0.8);
  transform: translate(0);
  margin: 0 7.5%;
}
@media only screen and (min-width: 480px) {
  .slidercontainer .slide_content,
  .banner .slide_content {
    margin: 0;
    bottom: 3rem;
    right: 3.5%;
    width: auto;
    padding-left: 2rem;
    max-width: 65%;
  }
}
@media only screen and (min-width: 900px) {
  .slidercontainer .slide_content,
  .banner .slide_content {
    max-width: 55%;
  }
}
.slide_content h2 {
  color: #fff;
  margin: 0;
}
.banner img {
  height: auto;
  width: 1920px;
}
.home-hero {
  padding: 4rem 0 0;
  position: relative;
  margin-bottom: 3.5rem;
  background-size: cover;
  background-position: center center;
  height: 100%;
}
@media only screen and (min-width: 900px) {
  .home-hero {
    padding: 4.5rem 0;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .home-hero {
    padding: 5rem 0 6rem;
  }
}
@media only screen and (min-width: 1500px) {
  .home-hero {
    padding: 6rem 0 6.5rem;
    margin-bottom: 2.5rem;
  }
}
.hh-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, rgba(115,220,255,0.75) 40%, transparent 100%);
  z-index: 1;
}
.hh-content {
  position: relative;
  margin: 0 auto;
  width: 90%;
  max-width: 72rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
@media only screen and (min-width: 900px) {
  .hh-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.hh-text {
  margin-bottom: 1rem;
  padding: 0 1rem;
}
@media only screen and (min-width: 900px) {
  .hh-text {
    width: 48%;
    align-self: center;
  }
}
.hh-text h1 {
  line-height: 1.4em;
  color: #fff;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) {
  .hh-text h1 {
    font-size: 2.1rem;
  }
}
.hh-video {
  transform: translateY(20px);
}
@media only screen and (min-width: 900px) {
  .hh-video {
    width: 48%;
  }
}
.hh-video iframe {
  width: 100%;
  height: 260px;
}
@media only screen and (min-width: 520px) {
  .hh-video iframe {
    height: 360px;
  }
}
@media only screen and (min-width: 900px) {
  .hh-video iframe {
    height: 300px;
  }
}
@media only screen and (min-width: 1200px) {
  .hh-video iframe {
    height: 380px;
  }
}
.page-hero-title {
  padding-top: 4rem;
}
@media only screen and (min-width: 900px) {
  .page-hero-title {
    padding-top: 6rem;
  }
}
.tp-home .slider-image {
  height: 550px;
  min-height: 550px;
}
@media only screen and (min-width: 740px) {
  .tp-home .slider-image {
    min-height: 520px;
    min-height: 520px;
  }
}
.home-banner {
  position: relative;
  margin-bottom: 3rem;
  height: 550px;
  min-height: 550px;
}
@media only screen and (min-width: 740px) {
  .home-banner {
    height: 520px;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 900px) {
  .home-banner .row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.home-banner .half {
  margin-bottom: 0;
  margin-top: 0;
}
@media only screen and (min-width: 900px) {
  .home-banner .half {
    width: 48%;
    margin-bottom: 0;
    margin-right: 4%;
  }
  .home-banner .half:nth-of-type(2n) {
    margin-right: 0;
  }
}
.home-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, rgba(115,220,255,0.75) 40%, transparent 100%);
}
.home-banner .iframe-half {
  position: relative;
  top: 1.5rem;
}
@media only screen and (min-width: 900px) {
  .home-banner .iframe-half {
    margin-left: auto;
    top: 0;
    padding-top: 3rem;
  }
}
.home-banner .banner-content {
  display: flex;
  align-items: center;
  text-align: center;
}
.home-banner .banner-content iframe {
  position: relative;
  width: 100%;
  height: 260px;
}
@media only screen and (min-width: 480px) {
  .home-banner .banner-content iframe {
    height: 360px;
  }
}
@media only screen and (min-width: 900px) {
  .home-banner .banner-content iframe {
    height: 380px;
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .home-banner .banner-content iframe {
    height: 380px;
  }
}
.home-banner .banner-content .home-banner-title {
  padding: 3.5rem 0 1rem;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.home-banner .banner-content .home-banner-title button {
  display: none;
}
@media only screen and (min-width: 400px) {
  .home-banner .banner-content .home-banner-title {
    padding-top: 3.5rem;
    max-width: 92%;
  }
}
@media only screen and (min-width: 900px) {
  .home-banner .banner-content .home-banner-title {
    padding-top: 3rem;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.home-banner .banner-content .home-banner-title h1 {
  color: #fafafa;
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 2rem;
  line-height: 1.2em;
  margin-bottom: 0;
}
@media only screen and (min-width: 900px) {
  .home-banner .banner-content .home-banner-title h1 {
    text-align: left;
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
}
nav.mobile {
  display: none;
}
@media only screen and (min-width: 900px) {
  nav.mobile {
    display: none !important;
  }
}
nav.mobile ul {
  margin: 0;
  padding: 0;
}
nav.mobile ul li {
  width: 100%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
}
nav.mobile ul li:last-of-type {
  border-bottom: none;
}
nav.mobile ul li a {
  display: inline-block;
  text-decoration: none;
  font-style: normal;
  background: #5cc2e8;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 1.1rem 0;
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 1.2rem;
}
nav.mobile ul li a:hover,
nav.mobile ul li a.active {
  background: #2e2e4d;
}
footer {
  padding: 1rem 0 1.5rem;
}
footer hr {
  margin: 0 0 2rem;
  border: none;
  border-bottom: 1px solid #eee;
}
footer p {
  font-size: 0.8rem;
  margin: 0 0 0.6rem;
}
h6.footer-heading {
  margin-bottom: 0.5rem;
}
h6.footer-heading:last-of-type {
  margin-bottom: 1.25rem;
}
p.footer-contact {
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 1rem;
  color: #5cc2e8;
  font-weight: normal;
}
p.footer-contact a {
  font-family: 'Roboto Slab', Georgia, serif;
  text-decoration: none;
}
.footer-companies {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 3rem;
}
@media only screen and (min-width: 900px) {
  .footer-companies {
    justify-content: flex-end;
    margin-top: 0;
  }
}
.footer-companies .company {
  margin-right: 10px;
}
@media only screen and (min-width: 900px) {
  .footer-companies .company {
    margin-right: 0;
    margin-left: 10px;
    width: 80px;
  }
}
.footer-companies .company:last-of-type {
  margin: 0;
}
.footer-companies .company img {
  width: 60px;
}
p.creators a {
  display: inline;
}
p.creators a:after {
  display: none;
}
ul.footer-navigation {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
ul.footer-navigation li {
  margin: 4px 15px 4px 0;
  line-height: 1.2em;
}
ul.footer-navigation li:last-of-type {
  margin-right: 0;
}
ul.footer-navigation li a {
  font-size: 0.9rem;
  text-decoration: none;
  font-family: 'Roboto Slab', Georgia, serif;
  font-weight: 700;
}
ul.footer-navigation li a:hover {
  color: #40b7e4;
}
.featured-file {
  text-align: center;
  padding: 0 0 4rem;
  position: relative;
}
@media only screen and (min-width: 900px) {
  .featured-file {
    padding: 1rem 0 6rem;
  }
}
.featured-file .checklist-text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 480px) {
  .featured-file .checklist-text {
    max-width: 35rem;
    margin-bottom: 2.5rem;
  }
}
.featured-file .checklist-title {
  margin: 0 auto 1rem;
  max-width: 90%;
}
.featured-file form {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.featured-file form input {
  font-size: 1rem;
  font-family: 'Roboto Slab', Georgia, serif;
  padding: 0.8rem 1rem;
}
.featured-file form input[type="submit"] {
  margin-top: 0.8rem;
  width: 100%;
  font-size: 1.2rem;
}
.featured-file .checklist-fields input {
  max-width: 50rem;
}
.featured-file .checklist-fields input:not(:last-of-type) {
  margin-bottom: 2rem;
}
.file-box {
  text-align: left;
  width: 45rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}
@media only screen and (min-width: 900px) {
  .file-box .button {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 900px) {
  .file-box aside {
    margin-top: 1rem;
    width: 100%;
  }
}
.file-box.flex-center {
  align-items: center;
}
.file-title {
  margin-bottom: 1rem;
  font-size: 2rem;
}
.file-summary {
  margin-bottom: 0.6rem;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.file-info {
  font-size: 1.2rem;
  font-family: 'Roboto Slab', Georgia, serif;
  color: #5cc2e8;
  margin-bottom: 0;
}
#featured-file span.error {
  font-size: 0.9rem;
  padding: 0;
  border: none;
}
.ff-home {
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 900px) {
  .ff-home {
    margin-bottom: 2rem;
  }
}
.ff-home input.btn-submit {
  margin: 0 auto;
}
.cf-column {
  margin-bottom: 1.25rem;
}
.cf-column .recaptcha {
  max-width: 50rem;
  margin: auto;
}
.tp-home .content {
  padding-top: 0;
}
.home-sections section {
  padding: 4rem 0;
}
.home-sections section .button {
  margin-top: 5rem;
}
.home-sections a.btn-bordered {
  margin-top: 3rem;
}
@media only screen and (min-width: 900px) {
  .home-sections a.btn-bordered {
    margin-top: 5rem;
  }
}
.home-testimonials {
  padding: 3.6rem 0 5.2rem;
  background-color: #fafafa;
  text-align: center;
}
.home-services p.section-intro {
  text-align: center;
}
.section-title {
  color: #2e2e4d;
  margin-bottom: 1.8rem;
  text-align: center;
}
@media only screen and (min-width: 900px) {
  .section-title {
    font-size: 1.65rem;
  }
}
.section-title,
.section-intro {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.testimonial-boxes {
  margin-top: 3rem;
}
@media only screen and (min-width: 900px) {
  .testimonial-boxes {
    display: flex;
  }
}
.testimonial-box {
  background: #fff;
  padding: 1rem 2rem;
  text-align: center;
  margin-bottom: 3rem;
}
.testimonial-box:last-of-type {
  margin-bottom: 0;
}
@media only screen and (min-width: 740px) {
  .testimonial-box {
    padding: 1rem 3.2rem;
  }
}
@media only screen and (min-width: 900px) {
  .testimonial-box {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 0;
    padding: 1.2rem 2rem;
  }
  .testimonial-box:nth-of-type(2n) {
    margin-right: 0;
  }
}
.testimonial-box hr {
  max-width: 12rem;
  margin: 1.2rem auto;
}
.testimonial-box p {
  font-size: 1.05rem;
  color: #766c6a;
  line-height: 1.85rem;
}
.testimonial-avatar {
  margin-bottom: 1rem;
}
.testimonial-box-title {
  color: #2e2e4d;
  margin-bottom: 0.2rem;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}
.testimonial-box-subtitle {
  color: #5cc2e8;
  font-weight: 400;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}
.home-lower {
  padding: 3rem 0;
}
@media only screen and (min-width: 1200px) {
  .home-lower .wrap {
    display: flex;
  }
  .home-lower hr {
    display: none;
  }
}
.home-lower hr {
  margin: 3.3rem 0 3.1rem;
}
.home-lower p {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media only screen and (min-width: 1200px) and (min-width: 740px) {
  .home-lower .home-services {
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 740px) {
  .service-boxes {
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 900px) {
  .service-boxes {
    display: flex;
    flex-flow: row wrap;
  }
}
.service-box {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  .service-box {
    width: 48%;
    margin-right: 4%;
  }
  .service-box:nth-of-type(2n) {
    margin-right: 0;
  }
}
.service-box img {
  width: 100%;
}
.sb-image {
  display: block;
  font-size: 0;
}
.home-service-title,
.home-news-title {
  color: #5cc2e8;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}
.home-news-title {
  font-size: 1.4rem;
  margin-top: 0;
}
.home-side-article {
  margin-bottom: 1rem;
}
.home-side-article:last-of-type {
  margin-bottom: 0;
}
.home-main-article-title {
  margin-bottom: 0.4rem;
}
.home-side-article-title {
  margin-bottom: 0;
}
.tp-services .content {
  padding-top: 0;
}
.service {
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  .service {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
}
.service:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
h2.category-title {
  font-family: 'Open Sans', "Lato", "Helvetica Neue", Arial;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: #2e2e4d;
}
@media only screen and (min-width: 900px) {
  h2.category-title {
    margin-bottom: 2rem;
  }
}
.service-tab-buttons {
  display: flex;
  flex-direction: column;
  transition: none;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 740px) {
  .service-tab-buttons {
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid #2e2e4d;
    margin-bottom: 1.4rem;
  }
}
.st-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  display: none;
}
@media only screen and (min-width: 640px) {
  .st-content {
    flex-direction: row;
    justify-content: space-between;
  }
}
.st-content.active {
  display: flex;
}
.st-lead {
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 640px) {
  .st-lead {
    width: 48%;
    margin-bottom: 4rem;
  }
}
.st-lead h3 {
  margin-bottom: 1rem;
}
.st-lead p {
  font-size: 1.05rem;
}
.stl-image {
  display: block;
  font-size: 0;
  margin: 0 0 1.25rem;
}
.service-page-cards {
  padding: 0 5%;
}
.service-page-cards.multi-cards {
  margin-bottom: 3.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.team-members {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-between;
}
@media only screen and (min-width: 800px) {
  .team-members {
    justify-content: flex-start;
  }
}
.team-members.with-department {
  margin-top: 2.5rem;
}
.team-member {
  flex: 1 100%;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 520px) {
  .team-member {
    flex: 0 47%;
  }
}
@media only screen and (min-width: 800px) {
  .team-member {
    flex: 0 30%;
    margin-right: 5%;
  }
  .team-member:nth-of-type(2n) {
    margin-right: 5%;
  }
  .team-member:nth-of-type(3n) {
    margin-right: 0;
  }
}
.member-title {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
.member-title.entry {
  margin-bottom: 0.2rem;
}
.member-title a {
  color: #766c6a;
}
.member-title a:hover {
  color: #6a615f;
}
.member-role {
  text-transform: none;
  color: #5cc2e8;
  font-weight: 400;
  font-family: 'Roboto Slab', Georgia, serif;
}
.member-role.entry {
  color: #5cc2e8;
}
@media only screen and (min-width: 480px) {
  :nth-of-type(2n) {
    margin-right: 0;
  }
}
.team-departments .team-department:not(:first-child) {
  margin-top: 1rem;
}
@media only screen and (min-width: 900px) {
  .team-departments .team-department:not(:first-child) {
    margin-top: 1.5rem;
  }
}
.team-department .td-summary {
  max-width: 50rem;
}
.team-department .td-title {
  position: relative;
  margin-bottom: 1rem;
}
.team-department .td-title::after {
  content: ' ';
  display: block;
  height: 1px;
  width: 100%;
  background-color: #e2e2e2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
}
.team-department .td-title h2 {
  margin-bottom: 0;
  background-color: #fff;
  padding-right: 1.5rem;
  z-index: 1;
  display: inline-block;
  position: relative;
}
.tp-team-member .member-title {
  margin-bottom: 0;
  font-size: 1.8rem;
}
.tp-team-member .member-role {
  color: #5cc2e8;
  font-size: 1.3rem;
}
.tp-team-member .social-icons {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.6rem;
}
.tp-team-member .social-icons a {
  margin-right: 1rem;
  font-size: 1.2rem;
  color: #000;
}
.tp-team-member .social-icons a:hover {
  color: #5cc2e8;
}
@media only screen and (min-width: 900px) {
  .tp-team-member .social-icons {
    justify-content: flex-end;
  }
  .tp-team-member .social-icons a {
    margin-right: 0;
    margin-left: 1rem;
  }
}
@media only screen and (min-width: 900px) {
  .tp-testimonials main {
    width: 65%;
  }
}
@media only screen and (min-width: 900px) {
  .tp-testimonials aside {
    width: 25%;
  }
}
.testimonial {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}
.testimonial-title {
  color: #5cc2e8;
  margin-bottom: 2rem;
}
.quoter-meta {
  margin-top: 2rem;
  padding: 0 5%;
}
@media only screen and (min-width: 900px) {
  .quoter-meta {
    text-align: center;
    padding-right: 3%;
    padding-left: 3%;
  }
}
.quoter-title {
  color: #2e2e4d;
  margin-bottom: 0;
}
@media only screen and (min-width: 900px) {
  .quoter-title {
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
}
.quoter-role {
  color: #5cc2e8;
  font-weight: 400;
}
.clients-title {
  text-align: center;
  width: 85%;
  max-width: 520px;
  margin: 0 auto;
  color: #766c6a;
  margin: 4rem auto;
}
.client-logos {
  display: flex;
  flex-flow: row wrap;
}
.client-logo {
  margin-bottom: 2rem;
  width: 100%;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  width: 50%;
  height: 200px;
  padding: 2rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.client-logo:last-of-type {
  margin-bottom: 0;
}
.client-logo:nth-of-type(2n) {
  border-right: none;
}
.client-logo:nth-last-child(-n+2) {
  border-bottom: none;
}
@media only screen and (min-width: 700px) {
  .client-logo {
    width: 33.3333%;
    padding: 3rem;
    height: 300px;
  }
  .client-logo:nth-of-type(2n) {
    border-right: 2px solid #eee;
  }
  .client-logo:nth-of-type(3n) {
    border-right: none;
  }
  .client-logo:nth-last-child(-n+2) {
    border-bottom: 2px solid #eee;
  }
  .client-logo:nth-last-child(-n+3) {
    border-bottom: none;
  }
}
.client-logo img {
  max-height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.client-logo img img {
  max-height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 480px) {
  .client-logo img img {
    max-height: 260px;
  }
}
@media only screen and (max-width: 900px) {
  .tp-articles .meta-social,
  .tp-articles a.more {
    display: none;
  }
}
.article {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  .article {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
}
.article:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.article-title {
  margin-bottom: 1rem;
}
.article-main:not(.with-sidebar) {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .article-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
}
@media only screen and (min-width: 900px) {
  .article-meta:not(.inline-display) {
    float: right;
    width: 15rem;
    margin-left: 3.5rem;
    margin-bottom: 4rem;
  }
}
.article-meta.inline-display {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 900px) {
  .article-meta.inline-display {
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 1.5rem;
  }
}
.author-meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .author-meta {
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 900px) {
  .inline-display .author-meta {
    margin-right: 1rem;
  }
}
.author-meta .avatar {
  width: 50px;
  height: 50px;
  margin-right: 0.6rem;
}
.meta-author {
  margin-bottom: 0;
  color: #5cc2e8;
  text-transform: none;
  font-size: 1rem;
}
.meta-date {
  text-transform: none;
  color: #766c6a;
  font-family: 'Roboto Slab', Georgia, serif;
  font-size: 0.85rem;
  margin-bottom: 0;
  margin-top: 0.2rem;
}
.meta-social {
  display: flex;
  margin: 0.85rem 0;
  border-top: 1px solid #eee;
  padding-top: 1.2rem;
  align-items: center;
}
@media only screen and (min-width: 900px) {
  .meta-social {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 900px) {
  .inline-display .meta-social {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }
}
.meta-icon {
  color: #5cc2e8;
  border: 2px solid #5cc2e8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-right: 0.6rem;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .meta-icon {
    width: 35px;
    height: 35px;
  }
}
.meta-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.meta-icon i.fa-twitter,
.meta-icon i.fa-facebook {
  font-size: 14px;
}
@media only screen and (min-width: 900px) {
  .meta-icon i.fa-twitter,
  .meta-icon i.fa-facebook {
    top: 53%;
    font-size: 16px;
  }
}
.meta-icon i.fa-facebook {
  left: 49%;
}
.meta-icon i.fa-paper-plane {
  font-size: 13px;
  top: 48%;
  left: 45%;
}
@media only screen and (min-width: 900px) {
  .meta-icon i.fa-paper-plane {
    font-size: 15px;
  }
}
.meta-icon:hover {
  color: #1c98c7;
  border-color: #1c98c7;
}
.author-bio {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
  background: #fff;
}
.author-bio .row {
  justify-content: space-around;
}
.author-bio-title {
  font-family: 'Roboto Slab', Georgia, serif;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.author-bio-text {
  font-size: 1.05rem;
}
.author-bio-rich-text p {
  font-size: 1.05rem;
}
@media only screen and (min-width: 900px) {
  .bio-meta {
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 900px) {
  .meta-social {
    border: none;
    padding: 0;
  }
}
body.download .content,
body.interest .content {
  background-color: #fafafa;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.download-page-contain {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 6rem;
}
@media only screen and (min-width: 900px) {
  .download-page-contain {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .download-page-contain {
    padding-bottom: 6rem;
  }
}
.download-column {
  position: relative;
  z-index: 2;
}
.download-column:not(:last-of-type) {
  margin-bottom: 3.25rem;
}
.download-column button.btn-solid,
.download-column a.btn-solid {
  margin-top: 1.5rem;
}
@media only screen and (min-width: 900px) {
  .download-column {
    flex-basis: 47%;
  }
}
@media only screen and (min-width: 1200px) {
  .download-column:first-of-type {
    margin-top: 0.5rem;
  }
}
.download-column.with-margin-top {
  margin-top: 2rem;
}
aside.download-aside-section .download-column {
  flex-basis: 100%;
  z-index: 1;
}
.download-column-title {
  color: #2e2e4d;
  margin-bottom: 1.35rem;
  line-height: 1.3em;
}
@media only screen and (min-width: 1200px) {
  .download-column-title {
    font-size: 1.95rem;
  }
}
.download-column.text-column p,
li {
  font-size: 1.05rem;
}
.download-column.text-column ul {
  margin: 1.5rem 0 1.8rem;
}
.download-column.text-column li {
  position: relative;
  padding-left: 2.2rem;
  color: #6d6362;
}
.download-column.text-column li::before {
  content: "\e901";
  font-family: "altruist", sans-serif;
  font-size: 1.5rem;
  vertical-align: bottom;
  position: absolute;
  left: 0;
  color: #5cc2e8;
}
.download-column.text-column li:not(:last-of-type) {
  margin-bottom: 1.15rem;
}
.download-column.text-column.brand-orange li::before {
  color: #fccd17;
}
.download-column.text-column.brand-green li::before {
  color: #92d150;
}
.hidden-honeypot {
  display: none !important;
}
.form-column {
  align-self: flex-start;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.8rem 1.25rem 2.5rem;
  border-top: 14px solid #73dcff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
@media only screen and (min-width: 900px) {
  .form-column {
    padding: 2.25rem 1.8rem 2.8rem;
    flex-basis: 45%;
  }
}
@media only screen and (min-width: 1200px) {
  .form-column {
    padding: 3rem 2.5rem 3.5rem;
  }
}
#download-form {
  align-self: flex-start;
  flex-basis: 100%;
  background-color: #fff;
}
#download-form.hide-form {
  display: none;
}
.form-columns {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form-columns:not(:last-of-type) {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 600px) {
  .form-columns:not(:last-of-type) {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 600px) {
  .form-columns {
    flex-direction: row;
    justify-content: space-between;
  }
}
.form-item.full-form {
  width: 100% !important;
}
@media only screen and (min-width: 600px) {
  .form-column .form-item {
    width: 48%;
  }
}
.form-item label {
  margin-bottom: 0.4rem;
}
.form-title {
  font-size: 1.3rem;
  text-align: center;
  max-width: 26rem;
  margin: 0 auto;
  color: #2e2e4d;
  margin-bottom: 2.5rem;
  font-weight: 400;
  line-height: 1.5em;
}
.download-aside .form-title {
  margin-bottom: 1.5rem;
  line-height: 1.4em;
}
@media only screen and (max-width: 480px) {
  .download-aside .form-title {
    font-size: 1.15rem;
  }
}
@media only screen and (min-width: 900px) {
  .download-aside .form-title {
    font-size: 1.1rem;
  }
}
.form-title.sidebar-download {
  margin-bottom: 1.5rem;
}
.form-success p:last-of-type {
  margin-bottom: 0;
}
.form-success.center-text {
  text-align: center;
}
.form-file-contain {
  padding-bottom: 2rem;
}
.form-file-detail {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  padding: 2.5rem 2.2rem;
}
@media only screen and (max-width: 480px) {
  .download-aside .form-file-detail {
    padding: 1.8rem 1.5rem;
  }
}
@media only screen and (min-width: 900px) {
  .download-aside .form-file-detail {
    padding: 1.8rem 1.5rem;
  }
}
.form-file-detail.with-stacked-display {
  flex-direction: column;
  padding: 1.8rem 1.5rem;
  margin-bottom: 1rem;
}
.file-icon-contain {
  display: flex;
  margin-right: 2rem;
}
.file-icon-contain i {
  font-size: 3rem;
  color: #5cc2e8;
}
@media only screen and (min-width: 480px) {
  .file-icon-contain i {
    font-size: 4.5rem;
  }
}
.file-icon-contain i.brand-green {
  color: #92d150;
}
.file-icon-contain i.brand-orange {
  color: #fccd17;
}
.with-stacked-display .file-icon-contain {
  margin-right: 0;
  margin-bottom: 1rem;
  margin-left: auto;
}
.with-stacked-display .file-icon-contain i {
  font-size: 1.8rem;
}
@media only screen and (min-width: 900px) {
  .download-aside .file-icon-contain {
    margin-right: 1.2rem;
  }
}
@media only screen and (min-width: 900px) {
  .download-aside .file-icon-contain i {
    font-size: 2.2rem;
  }
}
.form-file-information {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.form-file-item {
  color: #706765;
  font-weight: 400;
  font-size: 0.9rem;
  display: block;
  padding: 0;
  flex: 1 100%;
  line-height: 1.4em;
}
.form-file-item:not(:first-of-type) {
  margin-top: 0.85rem;
}
@media only screen and (min-width: 480px) {
  .form-file-item:nth-of-type(n+2) {
    display: inline-block;
    flex: 0 45%;
  }
  .form-file-item:nth-of-type(2n) {
    margin-right: 1rem;
  }
}
.form-file-item:before {
  content: ' ';
  display: none;
}
.form-file-item span {
  display: block;
  line-height: 1;
  font-family: 'Roboto Slab', Georgia, serif;
  color: #857a78;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 0.7rem;
}
.brand-icon-contain {
  position: absolute;
  bottom: -7rem;
  right: -4rem;
  z-index: 1;
}
.brand-icon-contain i {
  font-family: "altruist", sans-serif;
  font-size: 30rem;
  color: #e6f5fe;
}
.download-aside {
  padding-bottom: 2rem;
}
@media only screen and (min-width: 900px) {
  .download-aside {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 25px;
  }
}
@media only screen and (min-width: 900px) {
  .download-aside .form-columns {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 900px) {
  .download-aside .form-columns .form-item {
    width: 100%;
    margin-bottom: 20px;
  }
}
.download-aside.brand-orange {
  border-color: #fccd17;
}
.download-aside a.btn-solid {
  margin-top: 1.5rem;
}
.download-benefits p {
  font-size: 1.15rem;
  color: #2e2e4d;
}
.form-orange {
  border-color: #fccd17;
}
.form-orange input:focus {
  border-color: #fccd17;
  outline-color: #fccd17;
}
.form-green {
  border-color: #92d150;
}
.form-green input:focus {
  border-color: #92d150;
  outline-color: #92d150;
}
section.contact-side {
  margin-bottom: 3rem;
}
section.contact-side:last-of-type {
  margin-bottom: 0;
}
.contact-side-title {
  color: #5cc2e8;
  margin-bottom: 1rem;
}
span.error {
  display: inline-block;
  margin-top: 1rem;
  color: #c41926;
  font-size: 1.1rem;
  padding: 0.6rem 1rem;
  border: 1px solid #c41926;
}
.error-text {
  font-size: 0.9rem;
  display: block;
  margin-top: 0.4rem;
  color: #766c6a;
  font-family: 'Roboto Slab', Georgia, serif;
}
.form-errors {
  margin-bottom: 3rem;
}
.form-errors p {
  font-size: 1.1rem;
  font-family: 'Roboto Slab', Georgia, serif;
  color: #766c6a;
  margin-bottom: 0.2rem;
}
.form-errors p:last-of-type {
  margin-bottom: 0;
}
.form-errors p:before {
  content: '*';
  margin-right: 0.4rem;
}
form.mailchimp-form {
  display: none;
  overflow: hidden;
  height: 0;
  width: 0;
}
.form-captcha {
  padding-top: 1rem;
}
.btn-recaptcha {
  font-size: 1rem;
  display: inline-block;
  width: auto;
  background: #73dcff;
  color: #fff;
  margin-top: 2rem;
  font-family: 'Roboto Slab', Georgia, serif;
  cursor: pointer;
  font-weight: 700;
  border: 0;
  transition: background-color 200ms ease-out;
  padding: 0.8rem;
  border-radius: 0;
  border: 1px solid #4ed3ff;
  margin-top: 1rem;
  font-weight: 700;
}
.btn-recaptcha:hover {
  background-color: #4ed3ff;
}
.cf-checkbox {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto 0.85rem;
}
#cf-email-checkbox {
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  border: 0;
  margin: 0;
}
#cf-email-checkbox:hover ~ label::before {
  border-color: #7c7c7c;
}
#cf-email-checkbox:checked ~ label::before {
  background-color: #5cc2e8;
  border-color: #5cc2e8;
}
#cf-email-checkbox:checked ~ label::after {
  opacity: 1;
}
#cf-email-checkbox:checked:hover ~ label::before {
  border-color: #3ab5e3;
  background-color: #3ab5e3;
}
.cf-email-label,
.cf-email-label span {
  text-align: left;
  font-family: 'Open Sans', "Lato", "Helvetica Neue", Arial;
  font-size: 0.875rem;
  background-color: transparent;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0;
}
.cf-email-label {
  padding: 5px 5px 5px 28px;
}
.cf-email-label::before {
  content: ' ';
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #9b9b9b;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 3px;
}
.cf-email-label::after {
  content: '\f00c';
  font-family: "FontAwesome";
  display: flex;
  height: 13px;
  width: 13px;
  pointer-events: none;
  position: absolute;
  top: 9px;
  left: 2px;
  display: flex;
  line-height: 0;
  font-size: 14px;
  opacity: 0;
  color: #fff;
  justify-content: center;
  align-items: center;
}
.cf-checkbox.icon-push .cf-email-label::after {
  top: 10px;
}
@media only screen and (min-width: 900px) {
  .column-left {
    width: 60%;
  }
}
@media only screen and (min-width: 900px) {
  .scale-recaptcha {
    height: 61px;
  }
}
@media only screen and (min-width: 1200px) {
  .scale-recaptcha {
    height: 77px;
  }
}
@media only screen and (min-width: 900px) {
  .scale-recaptcha .g-recaptcha {
    position: relative;
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
@media only screen and (min-width: 1200px) {
  .scale-recaptcha .g-recaptcha {
    transform: scale(0.98);
  }
}
label {
  display: block;
  color: #766c6a;
  font-size: 0.85rem;
  margin-bottom: 0.2rem;
  font-family: 'Roboto Slab', Georgia, serif;
}
label.error {
  color: #c41926;
}
input,
textarea {
  appearance: none;
  border-radius: 0;
  width: 100%;
  font-size: 0.95rem;
  padding: 0.8rem;
  outline: 0;
  border: 2px solid #eee;
  color: #766c6a;
  font-family: 'Open Sans', "Lato", "Helvetica Neue", Arial;
}
input:focus {
  border-color: #5cc2e8;
  outline: 1px solid #5cc2e8;
}
.altruist-orange input:focus {
  border-color: #fccd17;
  outline: 1px solid #fccd17;
}
input.error {
  border-color: #c41926;
}
input.error:focus {
  outline: 0;
}
input[type="submit"] {
  border: 0;
  appearance: none;
  outline: 0;
}
textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 8rem;
  max-height: 14rem;
  line-height: 1.45em;
}
form .btn-solid {
  margin-top: 1.5rem;
}
.form-row {
  margin-top: 3rem;
}
@media only screen and (min-width: 640px) {
  .form-columns {
    display: flex;
    justify-content: space-between;
  }
  .form-columns .form-item {
    flex: 0 48%;
  }
}
@media only screen and (min-width: 900px) {
  .form-columns.stack-medium .form-item {
    flex: 1 100%;
  }
}
.form-item {
  margin-bottom: 2rem;
}
.form-item:last-of-type {
  margin-bottom: 0;
}
@media only screen and (min-width: 900px) {
  .form-item.sidebar-half {
    flex: 1 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .form-item.sidebar-half {
    flex: 0 48%;
  }
}
.form-item h6 {
  margin-bottom: 0.4rem;
  font-size: 0.85rem;
  color: #766c6a;
  font-weight: 400;
}
.arlo-introduction {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 900px) {
  .arlo-introduction {
    justify-content: space-between;
  }
}
.arlo-introduction aside {
  margin-top: 2rem;
  margin-bottom: 0;
  flex-basis: 100%;
}
@media only screen and (min-width: 900px) {
  .arlo-introduction aside {
    width: auto;
    padding-left: 0;
    border: 0;
    flex-basis: 40%;
    margin-top: 2.5rem;
  }
}
.arlo-introduction aside blockquote {
  margin: 0;
}
@media only screen and (min-width: 900px) {
  .arlo-introduction:not(.centered) .ai-column {
    width: 52%;
  }
}
@media only screen and (min-width: 900px) {
  .arlo-introduction.centered .ai-column {
    width: 68%;
    margin: 0 auto;
    text-align: center;
  }
}
@media only screen and (min-width: 900px) {
  .arlo-content-fields .arlo-content-field-text {
    width: 60%;
  }
}
@media only screen and (min-width: 900px) {
  .arlo-content-fields.full-width .arlo-content-field-text {
    width: 100%;
  }
}
.arlo-card-detail {
  border-top: 1px solid #f3f3f3;
  padding-top: 0.7rem;
  margin-top: 0.7rem;
}
.arlo-card-label {
  display: block;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
}
/*# sourceMappingURL=public/assets/stylesheets/stylus/style.css.map */