.tp-home
  .content
    padding-top 0
  
.home-sections
  section 
    padding 4rem 0
    .button 
      margin-top 5rem
  
  a.btn-bordered
    margin-top 3rem
    +above(medium)
      margin-top 5rem


.home-testimonials
  padding 3.6rem 0 5.2rem
  background-color #FAFAFA
  text-align center
      
.home-services
  p.section-intro
    text-align: center      
  
.section-title
  color dark-blue
  margin-bottom 1.8rem
  text-align center
  +above(medium)
    font-size 1.65rem
  // +above(large)
  //   font-size 2.2rem

.section-title, .section-intro
  max-width 75%
  margin-left auto
  margin-right auto

.testimonial-boxes 
  margin-top 3rem
  +above(medium)
    display flex

.testimonial-box
  background white 
  padding 1rem 2rem
  text-align center
  margin-bottom 3rem
  &:last-of-type
    margin-bottom 0
  
  +above(s-medium)
    padding 1rem 3.2rem

  +above(medium)
    width 48%
    margin-right 4%
    margin-bottom 0
    padding 1.2rem 2rem
    &:nth-of-type(2n)
      margin-right 0
  
  hr 
    max-width 12rem
    margin 1.2rem auto
  
  p 
    font-size 1.05rem
    color text
    line-height 1.85rem

.testimonial-avatar
  margin-bottom 1rem

.testimonial-box-title
  color dark-blue
  // font-size 1.45rem
  margin-bottom 0.2rem
  max-width 20rem
  margin-left auto
  margin-right auto

.testimonial-box-subtitle
  color brand 
  // font-size 1.2rem
  font-weight 400
  max-width 20rem
  margin-left auto
  margin-right auto
  
.home-lower
  padding 3rem 0
  +above(large)
    .wrap
      display flex
    hr 
      display none
    .home-services
      +above(s-medium)
        padding-top 2rem
    
  
  hr 
    margin 3.3rem 0 3.1rem

  p
    font-size 1rem
    line-height 1.5rem

.service-boxes
  +above(s-medium)
    padding-top 2rem
  +above(medium)
    display flex 
    flex-flow row wrap

.service-box
  margin-bottom 2rem
  +above(medium)
    width 48%
    margin-right 4%
    &:nth-of-type(2n)
      margin-right 0
  
  img
    width 100%

.sb-image
  display: block
  font-size: 0

.home-service-title
  color brand 
  // font-size 1.6rem
  margin-top 0.8rem
  margin-bottom: 1rem

.home-news-title
  @extends .home-service-title
  font-size 1.4rem
  margin-top 0

.home-side-article
  margin-bottom 1rem 
  &:last-of-type
    margin-bottom 0

.home-main-article-title 
  margin-bottom 0.4rem
  
.home-side-article-title 
  margin-bottom 0