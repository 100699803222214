.article-main
  &:not(.with-sidebar)
    width 100%

.article-meta
  +below(medium)
    display flex
    justify-content space-between
    align-items center
    margin-bottom 1.5rem
    flex-wrap: wrap
    border-bottom: 1px solid $border-grey
    padding-bottom 1rem

  &:not(.inline-display)
    +above(medium)
      float right
      width 15rem
      margin-left 3.5rem
      margin-bottom 4rem
  
  &.inline-display
    display: flex
    justify-content space-between
    +above(medium)
      margin-bottom 2rem
      border-bottom: 1px solid $border-grey
      padding-bottom 1.5rem

.author-meta
  display flex
  justify-content flex-start
  align-items center
  +below(medium)
    margin-right 1rem

  .inline-display &
    +above(medium)
      margin-right 1rem
  
  .avatar
    width: 50px
    height: 50px
    margin-right 0.6rem
  
.meta-author
  margin-bottom 0
  color brand
  text-transform none
  // font-size 1.1rem
  font-size 1rem
  
.meta-date
  text-transform none 
  color text 
  font-family heading-font
  // font-size 1rem 
  font-size .85rem
  margin-bottom 0
  margin-top 0.2rem

.meta-social
  display flex 
  margin: .85rem 0
  border-top 1px solid #EEE
  padding-top 1.2rem
  align-items center
  +above(medium)
    margin-bottom 0

  .inline-display &
    +above(medium)
      border: 0
      margin-top: 0
      padding-top: 0
 
 .meta-icon 
  color brand
  border 2px solid brand
  border-radius 50%
  width 40px
  height 40px
  display flex 
  justify-content center
  align-items center
  text-decoration none
  margin-right 0.6rem
  position relative

  +below(medium)
    width 35px
    height 35px
  
  i
    position absolute
    top: 50%
    left: 50%
    transform: translate3d(-50%,-50%,0)

    &.fa-twitter, &.fa-facebook
      font-size 14px
      +above(medium)
        top: 53%
        font-size 16px

    &.fa-facebook
      left: 49%

    &.fa-paper-plane
      font-size 13px
      top: 48%
      left: 45%
      +above(medium)
        font-size 15px

  &:hover
    color darken(brand, 30%)
    border-color darken(brand, 30%)
    
.author-bio
  margin-top 3rem
  padding-top 2rem
  border-top 1px solid #EEE
  background white
  .row
    justify-content space-around

.author-bio-title
  font-family heading-font
  margin-bottom 1.5rem
  margin-top 1rem
  
.author-bio-text
  font-size 1.05rem

.author-bio-rich-text
  p
    font-size: 1.05rem

.bio-meta
  +above(medium)
    display flex
    align-items center
 
+below(medium)  
  .meta-social
    border none
    padding 0
  