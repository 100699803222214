.tp-home
  .slider-image
    height 550px
    min-height 550px
    +above(s-medium)
      min-height 520px
      min-height 520px

.home-banner
  position relative
  margin-bottom 3rem
  height 550px
  min-height 550px
  +above(s-medium)
    height 520px
    margin-bottom 1.5rem
  
  .row
    +above(medium)
      flex-direction row
      flex-wrap wrap
      justify-content flex-start
      align-items center
    
  .half
    margin-bottom 0
    margin-top 0
    +above(medium)
      width 48%
      margin-bottom 0
      margin-right 4%
      &:nth-of-type(2n)
        margin-right 0
  
  .overlay
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    // background rgba(brand, 0.65)
    background linear-gradient(130deg, rgba(115, 220, 255, 0.75) 40%, transparent 100%)
  
  .iframe-half
    position relative
    top 1.5rem
    +above(medium)
      margin-left auto
      top 0
      padding-top 3rem
    // +above(medium)
    //   padding-top 2rem

  .banner-content
    display flex
    align-items center
    text-align center
  
    iframe
      position relative
      width 100%
      height 260px
      
      +above(mobile)
        height 360px
      
      +above(medium)
        height 380px
        width 100%
      
      +above(large)
        height 380px
        

    .home-banner-title 
      padding 3.5rem 0 1rem
      max-width 30rem
      margin-left auto 
      margin-right auto
      text-align left
      button
        display none
      +above(400px)
        padding-top 3.5rem
        max-width 92%
      +above(medium)
        padding-top 3rem
        max-width 100%
        margin-left 0
        margin-right 0
      h1
        color #FAFAFA
        font-family heading-font
        font-size 2rem
        line-height 1.2em
        margin-bottom 0
        +above(medium)
          text-align left
          font-size 2.6rem
          margin-bottom 1rem