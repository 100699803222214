button.nav-item-trigger, button.mobile-menu-trigger
  clear-buttons()

button.mobile-menu-trigger
  color white
  +above(medium)
    display: none
  
  &:hover
    span, i
      color: brand

  span, i
    transition: color 200ms ease-out
  
  span
    margin-right 2px
    display inline-block

button.nav-item-trigger
  width: 100%
  i
    pointer-events none
    position relative
    font-size 1.2rem
    top: 2px
    transform: scaleY(1)
    transform-origin: 50% 50%
    transition: transform 200ms ease-out
    margin-left 3px

  &.js-active
    i
      transform: scaleY(-1)

// desktop navigation
nav.header-navigation
  +equalBelow(medium)
    order: 1
    flex: 1 1 100%
    overflow: hidden
    display none
  
  +above(medium)
    margin-bottom 20px
    display flex !important

// nav parents
ul.nav-parents
  +above(medium)
    display flex
    align-items baseline

li.nav-item
  position relative
  line-height 1em

  +above(medium)
    display inline-flex
    &:not(&:last-of-type)
      margin-right 20px
      &::after
        content: ' '
        position absolute
        top: 50%
        transform: translate3d(0,-50%,0)
        right: -10px
        // background-color: white
        background-color: lighten(dark-blue, 50%)
        height: 14px
        width: 1px

  &:not(:first-of-type)
    +equalBelow(medium)
      border-top: 1px solid lighten(brand, 40%)
    

  a, button.nav-item-trigger
    font-weight 400
    color: white
    font-size 1rem
    display block
    cursor pointer
    position relative
    text-decoration none
    font-family heading-font

    +equalBelow(medium)
      padding: 1.05rem
      font-size 1rem
      background-color brand
      text-align center
      transition: background-color 200ms ease-out

      &:hover, &.js-active
        background-color darken(brand, 12%)
    
    +above(medium)
      font-size .9rem
      transition: color 200ms ease-out
      &:hover
        color: brand
    
    +above(large)
      font-size .925rem

// target last two dropdowns
li.nav-item
  +above(medium)
    &:nth-last-child(-n+2)
      ul.nav-children
        left: auto
        right: -5px
        text-align right

        &::before
          right: 15px
          left: auto

// target home link
li.nav-item:first-of-type
  +above(medium)
    display none
  +above(1010px)
    display block

// hide nav items on mobile
li.nav-item.mobile-hide
  +equalBelow(medium)
    display none

// nav children
ul.nav-children
  overflow: hidden
  +equalBelow(medium)
    max-height 0
    transition max-height 200ms ease-out
  
  +above(medium)
    max-height 100vh !important
    position absolute
    left: -18px
    top: 38px
    min-width: 180px
    width: 100%
    box-shadow: 0px 4px 10px 2px rgba(black, .2)
    transform: translate3d(0,5px,0)
    transition: opacity 200ms ease-out, transform 250ms ease-out
    background-color white
    overflow: visible
    border-radius: 3px
    pointer-events none
    opacity: 0
    z-index 0
    
    &.js-active
      transform translate3d(0,0,0)
      opacity: 1
      pointer-events all
      z-index 1
  
  li.sub-item a
    line-height 1.45em
    +equalBelow(medium)
      background-color lighten(brand, 12%)
      transition: background-color 200ms ease-out

      &:hover
        background-color lighten(brand, 18%)
    
    +above(medium)
      background-color white
      color: darken(text,10%)
      padding: .6rem 1rem
      transition: background-color 200ms ease-out

      &:hover
        background-color darken(white, 4%)
  
  li.sub-item
    +above(medium)
      overflow: hidden
      &:not(:last-of-type)
        border-bottom: 1px solid darken(white, 10%)
      
      &:first-of-type
        border-top-right-radius 3px
        border-top-left-radius 3px
      
      &:last-of-type
        border-bottom-left-radius 3px
        border-bottom-right-radius 3px

// ul nav children pointer
ul.nav-children::before
  +above(medium)
    content: ' '
    display block
    width 0
    height 0
    border-style solid
    border-width 0 8px 10px 8px
    border-color: transparent transparent white transparent
    position: absolute
    pointer-events: none
    top: -9px
    left: 15px
    z-index: -1

// phone prompt
a.phone-header
  text-decoration none
  font-family heading-font
  display flex
  align-items baseline
  margin-left: auto
  margin-right 24px
  position relative
  color: white
  +above($tablet)
    margin-right 32px
  +above(medium)
    display none

  &:hover
    span, i
      color: brand
  
  span, i
    color: inherit
    transition: color 200ms ease-out

  span
    font-size 1rem
    font-family heading-font
    margin-left 3px
    letter-spacing .2px

    &.prompt
      +above(mobile)
        display none
    &.number
      display none
      +above(mobile)
        display inline-block

  i.fa
    position relative
    font-size .9rem
    transform: rotate(8deg)
  
  &::after
    content: ' '
    display block
    width: 1px
    height: 14px
    background-color: white
    position absolute
    right: -11px
    top: 51%
    transform: translate3d(0,-50%,0)
    +above($tablet)
      right: -16px