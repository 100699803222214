.team-members
  display flex
  justify-content flex-start
  flex-flow row wrap
  margin-top 2rem
  justify-content space-between
  +above(800px)
    justify-content flex-start
  
  &.with-department
    margin-top 2.5rem

.team-member
  flex: 1 100%
  // width 100%
  margin-bottom 2rem

  +above($small)
    flex: 0 47%
  
  +above(800px)
    flex: 0 30%
    margin-right 5%
    &:nth-of-type(2n)
      margin-right 5%
    &:nth-of-type(3n)
      margin-right 0
  
.member-title
  margin-top 1rem
  margin-bottom 0.2rem
  &.entry
    margin-bottom 0.2rem
  a 
    color text
    &:hover
      color darken(text, 10%)

.member-role
  text-transform none
  color brand
  // font-size 1.3rem
  font-weight 400
  font-family heading-font
  &.entry
    color brand

+above(mobile)
  width 48%
  margin-right 4%
  &:nth-of-type(2n)
    margin-right 0

.team-departments
  .team-department:not(:first-child)
    margin-top 1rem
    +above(medium)
      margin-top 1.5rem

.team-department
  .td-summary
    max-width 50rem

  .td-title
    position relative
    margin-bottom 1rem
    
    &::after
      content: ' '
      display: block
      height: 1px
      width: 100%
      background-color darken($border-grey, 5%)
      position absolute
      top: 50%
      transform: translateY(-50%)
      left: 0
      z-index 0

    h2
      margin-bottom 0
      background-color #fff
      padding-right: 1.5rem
      z-index 1
      display inline-block
      position relative