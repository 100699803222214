.featured-file
  text-align center
  padding 0 0 4rem
  position relative
  +above(medium)
    padding 1rem 0 6rem
  
  // &::before, &::after
  //   content: ' '
  //   display: block
  //   height: 40px
  //   width: 40px
  //   background-image url('/assets/images/pattern.png')
  //   background-repeat no-repeat
  //   background-size contain
  //   position absolute
  //   z-index 0
  
  // &::before
  //   top 5%
  //   left 5%
  //   +above(medium)
  //     top 10%
  //     left 10%
  
  // &::after
  //   bottom 5%
  //   right 5%
  //   transform scale(-1,1)
  //   +above(medium)
  //     bottom 20px
  //     right 2%

  .checklist-text
    width 90%
    margin-left auto
    margin-right auto
    margin-bottom 2rem
    +above(mobile)
      max-width 35rem 
      margin-bottom 2.5rem
  .checklist-title
    margin 0 auto 1rem
    max-width 90%
  form
    // width 20rem
    max-width 100%
    margin-left auto
    margin-right auto
    input
      font-size 1rem
      font-family heading-font
      padding 0.8rem 1rem
      &[type="submit"]
        margin-top 0.8rem
        width 100%
        font-size 1.2rem
    
  .checklist-fields
    input
      max-width: 50rem
      &:not(:last-of-type)
        margin-bottom: 2rem
    // +above(mobile)
    //   max-width 90%
    //   width 40rem
    //   display flex
    //   margin 0 auto
    //   align-items center
    //   input[type="submit"]
    //     margin-top 0
    //     margin-left 0.6rem
    //     width auto

.file-box
  text-align left
  width 45rem
  max-width 100%
  margin-left auto
  margin-right auto
  align-items flex-start
  
  .button
    +above(medium)
      font-size 1.5rem
  
  aside
    +below(medium)
      margin-top 1rem
      width: 100%
  
  &.flex-center
    align-items center

.file-title
  margin-bottom 1rem
  font-size 2rem

.file-summary
  margin-bottom 0.6rem
  font-size 1.1rem
  margin-bottom 1rem

.file-info
  font-size 1.2rem
  font-family heading-font
  color brand
  margin-bottom 0

#featured-file
  span.error
    font-size 0.9rem
    padding 0
    border none
    
// Home Featured File
.ff-home
  margin-bottom: 1.5rem
  +above(medium)
    margin-bottom: 2rem 
  
  input.btn-submit
    margin 0 auto
    
// Updated Field HTML #4
.cf-column
  margin-bottom: 1.25rem

  .recaptcha
    max-width 50rem
    margin: auto