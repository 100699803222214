// text button
a.more
  text-decoration none
  display block
  margin-top 1.6rem
  font-size 1.05rem
  font-weight 700
  &:after
    content '→'
    margin-left 0.4rem
  &:hover
    color darken(brand, 10%)
  
  &.inline-block
    display inline-block
  &.small
    margin-top .5rem
  &.mt-0
    margin-top: 0
  
// new button styles
button, .btn-solid
  solid-button()
  border: 0

  // submit button
  &.btn-submit
    font-weight 700
    padding: .8rem
    display block
    width 100%

    &:not(.with-hover)
      background-color light-blue
      color: white
      transition background-color 200ms ease-out
      &:hover
        background-color darken(light-blue, 5%)
  
    &.with-hover
      position relative
      overflow: hidden
      span
        position relative
        color: white
        z-index 1
      
      &::after
        content: ' '
        display block
        position absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(black, .035)
        z-index 0
        opacity 0
        transition opacity 200ms ease-out
        pointer-events none

      &:hover::after
        opacity: 1
  
    &.brand-orange
      background-color $brand-orange
      &:hover
        background-color darken($brand-orange, 8%)
    &.brand-green
      background-color $brand-green
      &:hover
        background-color darken($brand-green, 8%)
    
  // border button
  &.btn-bordered
    display inline-block
    border-bottom 4px solid darken(brand, 20%)
    border-radius 5px
    background-color brand
    transition all 200ms ease-out
    color: white
    padding .8rem 1rem .6rem

    &:hover
      border-color darken(brand, 25%)
      background-color darken(brand, 5%)
    
    &.btn-large
      font-size 1.15rem
    
    &.btn-small
      font-size .95rem
      padding: .6rem 1rem

// find out
p.btn-contain
  a
    // @extend .button
    margin-top .5rem
    font-size .95rem
    padding: 5px 18px

// mobile menu
#nav_trigger
  &.button
    padding 23px 8.5px

// tab buttons
.st-button
  font-family: heading-font
  font-size: 1rem
  padding: 14px 18px
  background: brand
  border: 0
  color: white
  font-weight: 700
  border-radius: 5px 5px 0 0
  text-align: left
  transition: background .1s ease-out
  margin: 0
  
  &:hover, &.active
    background: dark-blue
    border-color: transparent
    
  &:not(:first-of-type)
    margin-left: 2px
  &:not(:last-of-type)
    margin-bottom: .5rem
    
    +above(s-medium)
      margin-bottom: 0