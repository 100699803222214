.tp-services
  .content
    padding-top 0

.service 
  padding-bottom 1rem
  border-bottom 1px solid #EEE
  margin-bottom 2rem
  +above(medium)
    padding-bottom 3rem
    margin-bottom 3rem 
  &:last-of-type
    margin-bottom 0
    border-bottom none
    padding-bottom 0
    
h2.category-title
  font-family: main-font
  font-size: 1.3rem
  margin-bottom: 1.5rem
  color: dark-blue
  +above(medium)
    margin-bottom: 2rem
    
// Service Tabs
.service-tab-buttons
  display: flex
  flex-direction: column
  transition: none
  margin-bottom: 1rem
  
  +above(s-medium)
    flex-direction: row
    align-items: flex-start
    border-bottom: 1px solid dark-blue
    margin-bottom: 1.4rem

.st-content
  display: flex
  flex-direction: column
  flex-wrap: wrap
  display: none
  
  +above($tablet)
    flex-direction: row
    justify-content: space-between

.st-content.active
  display: flex
  

// indivdual service block
.st-lead
  margin-bottom: 2.5rem
  
  +above($tablet)
    width: 48%
    margin-bottom: 4rem

.st-lead h3
  // line-height: 1em
  margin-bottom: 1rem
  // font-size: 1.5rem
  
  // +above(s-medium)
  //   font-size: 1.8rem

.st-lead p
  font-size: 1.05rem

.stl-image
  display: block
  font-size: 0
  margin: 0 0 1.25rem

// Service Page Cards
.service-page-cards
  padding: 0 5%
  &.multi-cards
    margin-bottom 3.5rem
    display: flex
    flex-wrap: wrap
    justify-content space-between