nav.mobile
  display none
  +above(medium)
    display none !important
  
  ul
    margin 0
    padding 0
    li
      width 100%
      height 100%
      text-align center
      border-bottom 1px solid white
      &:last-of-type
        border-bottom none
      a 
        display inline-block
        text-decoration none
        font-style normal
        background brand 
        color white
        width 100%
        height 100%
        padding 1.1rem 0
        font-family heading-font
        font-size 1.2rem
        &:hover, &.active
          background dark-blue
  