header.site-header
  position relative
  z-index 3

  +above(medium)
    background-color dark-blue

  section.mobile-wrap
    +equalBelow(medium)
      order: 2
      padding: 0 5%
      max-height: 90px
      height: 100%
      display: flex
      justify-content space-between
      align-items center
      background-color dark-blue
      flex: 1

  section.inner-wrap
    display: flex
    flex-wrap: wrap
    position relative
    +above(medium)
      wrapper()
      max-height 90px
      height: 100%
      justify-content space-between
      align-items center


.site-logo
  width: 130px
  height: 110px
  padding: 14px 10px 10px 10px
  display flex
  justify-content center
  background-color white
  align-self: flex-start
  align-items center

  img
    max-height: 70px
    height: auto
    width: auto

// header
//   background-color #2e2e4d
//   position relative
//   padding 0
//   height 4.8rem
//   z-index 3
//   .wrap
//     display flex
//     justify-content space-between
//     align-items center
//     height 100%

#logo
  position relative
  background-color white
  text-align center
  top 0.5rem
  img
    width 75%
    padding 1.2rem 0.1rem 0.6rem
    +above(medium)
      padding 2rem 0.3rem 0.8rem
      width 90%

.contact-header
  display flex 
  justify-content flex-end
  align-items center
  margin-left auto
  +above(medium)
    display none

// .phone-header
//   display flex
//   align-items center
//   text-decoration none
//   color white
//   +below(mobile)
//     display none
//   +above(medium)
//     display none
//   img
//     width 15px
//     height 100%
//     margin-right 0.25rem
//     position relative
//     transform: rotate(15deg)
//     top 1px

//   span
//     margin 0
//     color white
//     font-size 1rem
//     a
//       text-decoration: none
//       color: white

.nav-trigger
  padding 1.6rem 0.8rem
  // background brand
  text-decoration none
  display flex
  align-items center
  margin-left 1.5rem
  
  // &:hover
  //   background darken(brand, 10%)
  
  +above(medium)
    display none

  span 
    margin-right 0.6rem
    color white
    font-family heading-font
    font-size 1.1rem
    text-transform uppercase
    font-weight 700
  i 
    color white
    position relative
    top 1px
