// Pagination styling courtesy of Twitter Bootstrap
.pagination
  display inline-block
  padding-left 0
  margin 3rem 0 0
  border-radius 4px
  & > li
    display inline

.pagination > li > a,
.pagination > li > span
  position relative
  float left
  padding 6px 12px
  margin-left -1px
  font-size 0.9rem
  line-height 1.42857143
  color brand
  text-decoration none
  background-color #fff
  border 1px solid #ddd

.pagination > li:first-child > a,
.pagination > li:first-child > span
  margin-left 0
  border-top-left-radius 4px
  border-bottom-left-radius 4px

.pagination > li:last-child > a,
.pagination > li:last-child > span
  border-top-right-radius 4px
  border-bottom-right-radius 4px

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus
  z-index 2
  color #23527c
  background-color #eee
  border-color #ddd

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus
  z-index 3
  color #fff
  cursor default
  background-color brand
  border-color brand

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus
  color #777
  cursor not-allowed
  background-color #fff
  border-color #ddd
