.tp-articles
  +below(medium)
    .meta-social, a.more
      display none
  
.article
  padding-bottom 2rem
  border-bottom 1px solid #EEE
  margin-bottom 2rem
  +above(medium)
    padding-bottom 3rem
    margin-bottom 3rem
  &:last-of-type
    padding-bottom 0
    border-bottom none
    margin-bottom 0

.article-title
  margin-bottom 1rem
  // font-size 1.6rem
  // line-height 1.3em
  // +above(medium)
  //   font-size 1.8rem