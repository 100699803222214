label
  display block
  color text
  font-size .85rem
  margin-bottom 0.2rem
  font-family heading-font
  &.error
    color red 

input, textarea
  appearance none
  border-radius 0
  width 100%
  font-size .95rem
  padding 0.8rem
  outline 0
  border 2px solid #EEE
  color text
  font-family main-font

input 
  &:focus
    border-color brand
    outline 1px solid brand
    
    .altruist-orange &
      border-color: $brand-orange
      outline: 1px solid $brand-orange

  &.error
    border-color red
    &:focus
      outline 0

input[type="submit"]
  border: 0
  appearance none
  outline: 0

textarea
  max-width 100%
  min-width: 100%
  min-height: 8rem
  max-height 14rem
  line-height 1.45em

form
  .btn-solid
    margin-top 1.5rem

// form columns
.form-row
  margin-top 3rem

.form-columns
  +above($tablet)
    display flex
    justify-content space-between
    .form-item
      flex: 0 48%
  
  &.stack-medium
    .form-item
      +above(medium )
        flex: 1 100%


.form-item
  margin-bottom 2rem
  &:last-of-type
    margin-bottom 0
  
  &.sidebar-half
    +above(medium)
      flex: 1 100%
    +above(large)
      flex: 0 48%
    
  h6
    margin-bottom .4rem
    font-size .85rem
    color text
    font-weight 400