.tp-team-member
  
  .member-title
    margin-bottom 0
    font-size 1.8rem
  .member-role
    color brand
    font-size 1.3rem
  
  .social-icons
    display flex
    justify-content flex-start
    margin-top 0.6rem
    a 
      margin-right 1rem
      font-size 1.2rem
      color black
      &:hover
        color brand
    +above(medium)
      justify-content flex-end
      a 
        margin-right 0
        margin-left 1rem
      
    