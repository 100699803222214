footer
  padding 1rem 0 1.5rem
  hr
    margin 0 0 2rem
    border none
    border-bottom 1px solid #EEE
  
  p 
    font-size 0.8rem
    margin 0 0 0.6rem
    
h6.footer-heading
  margin-bottom: .5rem
  &:last-of-type
    margin-bottom: 1.25rem

p.footer-contact
  font-family: heading-font
  font-size: 1rem
  color: brand
  font-weight: normal
  a
    font-family: heading-font
    text-decoration: none

.footer-companies
  display flex
  flex-flow row wrap
  justify-content flex-start
  margin-top 3rem
  +above(medium)
    justify-content flex-end
    margin-top 0  
  
  .company
    margin-right 10px
    +above(medium)
      margin-right 0
      margin-left 10px
      width 80px
    &:last-of-type
      margin 0

    img
      width 60px

p.creators
  a
    display inline
    &:after
      display none

ul.footer-navigation
  display: flex
  flex-wrap: wrap
  margin-bottom 1.5rem

  li
    margin: 4px 15px 4px 0
    line-height 1.2em
    &:last-of-type
      margin-right 0
  
  li a
    font-size .9rem
    text-decoration none
    font-family heading-font
    font-weight 700
    &:hover
      button-hover()