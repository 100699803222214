
.content
  body.download &, body.interest &
    background-color #fafafa// #f7fdff
    padding-bottom 0
    position relative
    overflow hidden

.download-page-contain
  position relative
  display flex
  flex-direction column
  overflow hidden
  padding-bottom 6rem
  +above(medium)
    flex-direction row
    justify-content space-between
    padding-bottom 4.5rem
  +above(large)
    padding-bottom: 6rem

.download-column
  position relative
  z-index: 2
  &:not(:last-of-type)
    margin-bottom 3.25rem
  
  button.btn-solid, a.btn-solid
    margin-top 1.5rem
    
  +above(medium)
    flex-basis 47%
  +above(large)
    &:first-of-type
      margin-top .5rem
  
  &.with-margin-top
    margin-top 2rem
  
  aside.download-aside-section &
    flex-basis: 100%
    z-index: 1

.download-column-title
  color dark-blue
  margin-bottom 1.35rem
  line-height: 1.3em
  +above(large)
    font-size: 1.95rem

.download-column.text-column p, li
  font-size 1.05rem

.download-column.text-column
  ul
    margin 1.5rem 0 1.8rem

  li
    position relative
    padding-left 2.2rem
    color darken(text, 8%)
    &::before
      content "\e901"
      font-family: icon-font
      font-size 1.5rem
      vertical-align bottom
      position absolute
      left 0
      color brand
      
    &:not(:last-of-type)
      margin-bottom 1.15rem
  
  &.brand-orange
    li::before
      color $brand-orange
  &.brand-green
    li::before
      color $brand-green

// Download Form
.hidden-honeypot
  display none !important
  
.form-column
  align-self flex-start
  width 100%
  background-color white
  border-radius 8px
  padding 1.8rem 1.25rem 2.5rem
  border-top 14px solid #73dcff
  border-top-left-radius 8px
  border-top-right-radius 8px
  +above(medium)
    padding 2.25rem 1.8rem 2.8rem
    flex-basis 45%
  +above(large)
    padding 3rem 2.5rem 3.5rem
    
#download-form
  align-self flex-start
  flex-basis 100%
  background-color white
  
  &.hide-form
    display none

.form-columns
  width 100%
  display flex
  flex-direction column
  &:not(:last-of-type)
    margin-bottom 2rem
    +above(600px)
      margin-bottom 0

  +above(600px)
    flex-direction row
    justify-content space-between

.form-item.full-form
  width 100% !important

.form-column
  .form-item
    +above(600px)
      width 48%

.form-item label
  margin-bottom .4rem

// File Download View
.form-title
  font-size: 1.3rem
  text-align: center
  max-width 26rem
  margin 0 auto
  color dark-blue
  margin-bottom 2.5rem
  font-weight: 400
  line-height: 1.5em

  .download-aside &
    margin-bottom 1.5rem
    line-height 1.4em
    +below(mobile)
      font-size 1.15rem
    +above(medium)
      font-size 1.1rem
  
  &.sidebar-download
    margin-bottom: 1.5rem
  
.form-success
  p
    &:last-of-type
      margin-bottom 0
  &.center-text
    text-align center

.form-file-contain
  padding-bottom: 2rem

.form-file-detail
  background-color #fcfcfc
  display flex
  flex-direction row
  padding 2.5rem 2.2rem

  .download-aside &
    +below(mobile)
      padding: 1.8rem 1.5rem
    +above(medium)
      padding: 1.8rem 1.5rem
  
  &.with-stacked-display
    flex-direction column
    padding: 1.8rem 1.5rem
    margin-bottom 1rem

.file-icon-contain
  display flex
  margin-right 2rem
  i
    font-size: 3rem
    color brand
    +above(mobile)
      font-size: 4.5rem
    
    &.brand-green
      color: $brand-green
    &.brand-orange
      color: $brand-orange
  
  .with-stacked-display &
    margin-right 0
    margin-bottom 1rem
    margin-left auto
    i
      font-size 1.8rem
  
  .download-aside &
    +above(medium)
      margin-right: 1.2rem
    i
      +above(medium)
        font-size: 2.2rem

.form-file-information
  margin 0
  display flex
  flex-wrap wrap

.form-file-item
  color darken(text, 5%)
  font-weight: 400
  font-size .9rem
  display block
  padding 0
  flex 1 100%
  line-height 1.4em

  &:not(:first-of-type)
    margin-top .85rem

  +above(mobile)
    &:nth-of-type(n+2)
      display inline-block
      flex 0 45%
    
    &:nth-of-type(2n)
      margin-right: 1rem
  
  &:before
    content ' '
    display none
  
  span
    display block
    line-height 1
    font-family: heading-font
    color lighten(text, 10%)
    margin-bottom .25rem
    text-transform uppercase
    letter-spacing .4px
    font-size .7rem
  
// Brand Icon
.brand-icon-contain
  position absolute
  bottom -7rem
  right -4rem
  z-index 1
  i
    font-family: icon-font
    font-size: 30rem
    color #e6f5fe
    

// Services Sidebar
.download-aside
  padding-bottom: 2rem
  +above(medium)
    padding-right: 5px
    padding-left: 5px
    padding-top: 25px
  .form-columns
    +above(medium)
      flex-wrap: wrap
    .form-item
      +above(medium)
        width: 100%
        margin-bottom: 20px
  
  &.brand-orange
    border-color: $brand-orange
  
  a.btn-solid
    margin-top 1.5rem

.download-benefits
  p
    font-size: 1.15rem
    color: dark-blue

// form colors
.form-orange
  border-color: $brand-orange

  input:focus
    border-color: $brand-orange
    outline-color: $brand-orange

.form-green
  border-color: $brand-green

  input:focus
    border-color: $brand-green
    outline-color: $brand-green