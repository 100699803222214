antialiased()
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  
align-vertical()
  position relative
  top 50%
  transform translate(-50%)
  
flex-layout($direction = column)
  display: flex
  flex-direction: $direction
  
wrapper()
  margin: 0 auto
  width: $wrap-width
  max-width: $wrap-mwidth
  
background-cover()
  background-size: cover
  background-position: center center
  height: 100%

solid-button()
  font-size 1rem
  font-family heading-font
  cursor pointer
  text-decoration none
  text-align center
  
btn-submit()
  font-size 1rem
  display inline-block
  width auto
  background light-blue
  color white
  margin-top 2rem
  font-family heading-font
  cursor pointer
  font-weight 700
  border: 0
  transition: background-color 200ms ease-out
  &:hover
    background-color darken(light-blue, 10%)

clear-buttons()
  appearance none
  padding: 0
  border: 0
  margin: 0
  outline: none
  border-radius: 0
  background: transparent
  font-size 1rem
  &:active
    top: 0px

equalBelow(breakpoint)
  condition = 'screen and (max-width: calc(%s - 1px))' % breakpoint
  @media condition
    {block}

button-hover()
  color darken(brand, 10%)